export const phoneMask: any = [
  "(",
  "+",
  "5",
  "6",
  ")",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
