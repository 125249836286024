import DefaultTemplate from "../../templates/DefaultTemplate";

const UsersDoc = () => {
    return (
        <DefaultTemplate>
            <div className="row mx-0 px-4 py-5 with-header">
                <div className="default-template-box mb-3 p-4 size-09">
                    <div className="col-12">
                        <h5>Super Administrador</h5>
                    </div>
                    <div className="col-12">
                        Usuario destinado a la administración general del sistema. Son usuarios exclusivos para administradores de elock. 
                        Los usuarios super administrador pueden crear, editar y eliminar.
                    </div>

                    <div className="col-12">
                        <span>Permisos</span>
                        <ul>
                            <li>Crear - Global</li>
                            <li>Editar - Global</li>
                            <li>Eliminar - Global</li>
                        </ul>
                    </div>

                    <div className="col-12">
                        <span>Menus</span>
                        <ul>
                            <li>Dashboard</li>
                            <li>Clientes</li>
                            <li>Empresas</li>
                            <li>Terminales</li>
                            <li>Cajas</li>
                            <li>Agregar Usuarios</li>
                            <li>Órdenes</li>
                            <li>Template Email</li>
                            <li>Couriers</li>
                            <li>Logs</li>
                            <li>Documentacion</li>
                        </ul>
                    </div>
                </div>

                <div className="default-template-box mb-3 p-4">
                    <div className="col-12">
                        <h5>Administrador Empresa</h5>
                    </div>

                    <div className="col-12">
                        Usuario destinado a la administración de una empresa.
                    </div>

                    <div className="col-12">
                        <span>Permisos</span>
                        <ul>
                            <li>Crear - Usuario Plataforma</li>
                            <li>Editar - Usuario Plataforma</li>
                            <li>Crear - Usuario Terminal</li>
                            <li>Editar - Usuario Terminal</li>
                            <li>Crear - Orden</li>
                        </ul>
                    </div>

                    <div className="col-12">
                        <span>Menus</span>
                        <ul>
                            <li>Dashboard</li>
                            <li>Usuarios</li>
                            <li>Empresas</li>
                            <li>Cajas</li>
                            <li>Agregar Usuarios</li>
                            <li>Órdenes</li>
                        </ul>
                    </div>
                </div>

                <div className="default-template-box mb-3 p-4">
                    <div className="col-12">
                        <h5>Asistente empresa</h5>
                    </div>

                    <div className="col-12">
                        <span>Permisos</span>
                        <ul>
                            <li>Crear - Orden</li>
                            <li>Ver todas órdenes de la empresa</li>
                        </ul>
                    </div>

                    <div className="col-12">
                        <span>Menus</span>
                        <ul>
                            <li>Cajas</li>
                            <li>Órdenes</li>
                        </ul>
                    </div>
                </div>

                <div className="default-template-box mb-3 p-4">
                    <div className="col-12">
                        <h5>Usuario</h5>
                    </div>

                    <div className="col-12">
                        <span>Permisos</span>
                        <ul>
                            <li>Crear - Orden</li>
                            <li>Ver solo las ordenes creadas por el</li>
                        </ul>
                    </div>

                    <div className="col-12">
                        <span>Menus</span>
                        <ul>
                            <li>Cajas</li>
                            <li>Órdenes</li>
                        </ul>
                    </div>
                </div>

                <div className="default-template-box mb-3 p-4">
                    <div className="col-12">
                        <h5>Acceso API</h5>
                    </div>

                    <div className="col-12">
                        Usuario para acceso externo a la api de Elock.
                    </div>
                </div>


            </div>
        </DefaultTemplate>
    );
}

export default UsersDoc;