import { useNavigate } from "react-router-dom";

const ItemMenu = (props: any) => {
  const { Icon, name, selected, url } = props;
  const navigate = useNavigate();
  return (
    <div
      className={`default-template-menu-item mt-3 ${
        selected ? "selected" : ""
      }`}
    >
      <button
        className="px-4 py-2 w-100 text-start bold-300 d-flex align-items-center"
        onClick={() => navigate(url)}
      >
        <Icon
          className="me-2"
          style={{ fontSize: "25px", width: "25px", height: "25px" }}
        />{" "}
        <span>{name}</span>
      </button>
    </div>
  );
};

export default ItemMenu;
