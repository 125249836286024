/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";

import "./style.scss";

const Loader = () => {
  const { loader } = useSelector((state: any) => state);
  const { is_loading } = loader;

  return (
    <div className={`container-loader ${is_loading ? "d-flex" : "d-none"}`}>
      <div
        className="spinner-border text-light"
        style={{ width: "80px", height: "80px" }}
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default Loader;
