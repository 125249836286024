/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Menu from "./Menu";
import "./styles.scss";

const DefaultTemplate = (props: any) => {
  const { children } = props;

  const { session } = useSelector((state: any) => state);
  const navigate = useNavigate();

  useEffect(() => {
    if (!session.is_logged) {
      navigate('/sign-in')
    }
  }, [session]);
  return (
    <div className="container-fluid" style={{ minHeight: "100vh" }}>
      <div className="row">
        <div className="default-template-menu p-0 m-0 position-relative">
          <Menu />
        </div>
        <div className="col px-0 mx-0 default-template-content">
          <Header />
          {children}
        </div>
      </div>
    </div>
  );
};

export default DefaultTemplate;
