export default interface IBox {
  name: string;
  width: string;
  height: string;
  depht: string;
}

export const attributes = {
  name: "Nombre",
  phone: "Telefóno",
  email: "Email",
  rut: "Rut",
  address: "Dirección",
  apartment: 'Departamento',
  company: "Empresa",
};
