import { ResponsiveBar } from "@nivo/bar";

const ChartBar = (props: any) => {
  const { data, title, keys, colors, indexBy } = props;

  return (
    <>
      <div className="py-3">{title}</div>
      <div className="w-100 h-100">
        <ResponsiveBar
          data={data}
          keys={keys}
          indexBy={indexBy}
          groupMode="grouped"
          margin={{ top: 10, right: 10, bottom: 100, left: 60 }}
          padding={0.4}
          valueScale={{ type: "linear" }}
          colors={colors}
          animate={false}
          enableLabel={false}
          axisTop={null}
          axisRight={null}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: title,
            legendPosition: "middle",
            legendOffset: -40,
          }}
        />
      </div>
    </>
  );
};

export default ChartBar;
