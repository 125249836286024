export const configs = [
  {
    desk_door_number: "1",
    desk_number: "0",
    door_number: "1",
    box_type_id: 3,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "2",
    desk_number: "0",
    door_number: "2",
    box_type_id: 1,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "3",
    desk_number: "0",
    door_number: "3",
    box_type_id: 1,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "4",
    desk_number: "0",
    door_number: "4",
    box_type_id: 2,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "5",
    desk_number: "0",
    door_number: "5",
    box_type_id: 2,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "6",
    desk_number: "0",
    door_number: "6",
    box_type_id: 3,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "7",
    desk_number: "0",
    door_number: "7",
    box_type_id: 1,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "8",
    desk_number: "0",
    door_number: "8",
    box_type_id: 1,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "9",
    desk_number: "0",
    door_number: "9",
    box_type_id: 1,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "10",
    desk_number: "0",
    door_number: "10",
    box_type_id: 1,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "11",
    desk_number: "0",
    door_number: "11",
    box_type_id: 1,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "12",
    desk_number: "0",
    door_number: "12",
    box_type_id: 1,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "13",
    desk_number: "0",
    door_number: "13",
    box_type_id: 1,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "14",
    desk_number: "0",
    door_number: "14",
    box_type_id: 2,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "15",
    desk_number: "0",
    door_number: "15",
    box_type_id: 2,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
];

export const configs_side = [
  {
    desk_door_number: "1",
    desk_number: "1",
    door_number: "16",
    box_type_id: 3,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "2",
    desk_number: "1",
    door_number: "17",
    box_type_id: 2,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "3",
    desk_number: "1",
    door_number: "18",
    box_type_id: 1,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "4",
    desk_number: "1",
    door_number: "19",
    box_type_id: 1,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "5",
    desk_number: "1",
    door_number: "20",
    box_type_id: 1,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "6",
    desk_number: "1",
    door_number: "21",
    box_type_id: 1,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "7",
    desk_number: "1",
    door_number: "22",
    box_type_id: 1,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "8",
    desk_number: "1",
    door_number: "23",
    box_type_id: 2,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "9",
    desk_number: "1",
    door_number: "24",
    box_type_id: 2,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "10",
    desk_number: "1",
    door_number: "25",
    box_type_id: 3,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "11",
    desk_number: "1",
    door_number: "26",
    box_type_id: 1,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "12",
    desk_number: "1",
    door_number: "27",
    box_type_id: 1,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "13",
    desk_number: "1",
    door_number: "28",
    box_type_id: 1,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "14",
    desk_number: "1",
    door_number: "29",
    box_type_id: 1,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "15",
    desk_number: "1",
    door_number: "30",
    box_type_id: 1,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "16",
    desk_number: "1",
    door_number: "31",
    box_type_id: 1,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "17",
    desk_number: "1",
    door_number: "32",
    box_type_id: 1,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "18",
    desk_number: "1",
    door_number: "33",
    box_type_id: 2,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
  {
    desk_door_number: "19",
    desk_number: "1",
    door_number: "34",
    box_type_id: 2,
    hexadecimal: '',
    model_type: 1,
    is_enabled: true,
    new: true,
    validations: {},
  },
];


export const models:any = [
  {
    value: 1,
    label: 'Driveboard 01 - Driver Chino'
  },
  {
    value: 2,
    label: 'Driveboard 02 - Driver Gux'
  },
];