import EditIcon from "@rsuite/icons/Edit";
import ParagraphIcon from "@rsuite/icons/Paragraph";
import TrashIcon from "@rsuite/icons/Trash";
import {FiBox} from 'react-icons/fi';

export const _actionMenuSpeaker = (data: any, _handleOnSelect: any) => {
  return {
    menu: [
      {
        label: "Detalles",
        Icon: ParagraphIcon,
        show: true,
        eventKey: 2,
      },
      {
        label: "Editar",
        Icon: EditIcon,
        show: true,
        eventKey: 1,
      },
      {
        label: "Eliminar",
        Icon: TrashIcon,
        show: true,
        eventKey: 3,
      },
      {
        label: "Cajas",
        Icon: FiBox,
        show: true,
        eventKey: 4,
      },
    ],
    _handleOnClick: (eventKey: any, _speaker: any) =>
      _handleOnSelect({ option: eventKey, id: data.id }),
    user: data,
  };
};

export const _actionMenuSpeakerAll = (_handleOnSelect: any) => {
  return {
    menu: [
      {
        label: "Exportar",
        Icon: ParagraphIcon,
        show: true,
        eventKey: 1,
      },
      {
        label: "Eliminar seleccionados",
        Icon: TrashIcon,
        show: true,
        eventKey: 2,
      },
    ],
    _handleOnClick: (eventKey: any, _speaker: any) =>
      _handleOnSelect({ option: eventKey }),
  };
};
