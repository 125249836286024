import { cloneElement } from "react";
import { Modal } from "rsuite";
import { TiWarningOutline } from "react-icons/ti";

const ModalComponent = (props: any) => {
  const { handleClose, size, open, title, children, type, backdrop } = props;
  return (
    <Modal size={size} open={open} onClose={() => handleClose()} backdrop={backdrop ? backdrop : "static"}>
      <Modal.Header>
        <Modal.Title>
          {type === "warning" && (
            <TiWarningOutline
              className="me-3"
              style={{ color: "orange", fontSize: "1.6rem" }}
            />
          )}

          <span className="size-09 bold">{title}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {cloneElement(children, { handleClose: handleClose })}
      </Modal.Body>
    </Modal>
  );
};

export default ModalComponent;
