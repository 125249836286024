import { Table } from "rsuite";
import WhisperTooltip from "../../components/WhisperTooltip";

const ErrorsTable = (props: any) => {
  const whisperStyle: any = {
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  const tableHeader: any = [
    {
      label: "Fila",
      key: "line",
      align: "left",
      alignFlex: "flex-start",
      flexGrow: 1,
    },
    {
      label: "Campo",
      key: "field",
      align: "left",
      alignFlex: "flex-start",
      flexGrow: 1,
    },
    {
      label: "Error",
      key: "error_text",
      align: "left",
      alignFlex: "flex-start",
      flexGrow: 1,
    },
    {
      label: "Valor",
      key: "text",
      align: "left",
      alignFlex: "flex-start",
      flexGrow: 1,
    },
  ];
  return (
    <div className="container-fluid">
      <Table
        data={props.data}
        rowClassName="striped"
        autoHeight
        rowHeight={80}
        locale={{ emptyMessage: "No encontramos ningún dato." }}
      >
        {tableHeader &&
          tableHeader.map((column: any, index: any) => (
            <Table.Column
              align={column.align}
              flexGrow={column.flexGrow}
              key={`table-column-${index}`}
            >
              <Table.HeaderCell>
                <span className="bold" style={{ textTransform: "capitalize" }}>
                  {column.label}
                </span>
              </Table.HeaderCell>

              <Table.Cell
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: column.alignFlex,
                }}
              >
                {(rowData) => {
                  switch (column.key) {
                    default:
                      return (
                        <WhisperTooltip
                          className="size-08"
                          style={whisperStyle}
                          data={rowData[column.key]}
                        />
                      );
                  }
                }}
              </Table.Cell>
            </Table.Column>
          ))}
      </Table>
    </div>
  );
};

export default ErrorsTable;
