const ModuleCard = ({ name, code, desc }: any) => {
    return (
        <div className="default-template-box mb-3 p-4">
            <div className="col-12">
                <h5>{name}</h5>
            </div>

            <div className="col-12 py-2">
                <span className="bold">Codigo: </span>
                <span>{code}</span>
            </div>

            <div className="col-12 py-2">
                <span className="bold">Descripcion: </span>
                <span>
                    {desc}
                </span>
            </div>
        </div>
    )
}

export default ModuleCard;