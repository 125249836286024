import { Tooltip, Whisper } from "rsuite";

const ButtonIcon = (props: any) => {
  const { label, tooltip, Icon, width, height, color, size, onClick, ...rest } =
    props;

  if (tooltip) {
    return (
      <Whisper
        placement="auto"
        trigger="hover"
        speaker={<Tooltip>{tooltip}</Tooltip>}
      >
        <button
          className="btn d-flex flex-column justify-content-center align-items-center mx-2"
          onClick={onClick}
          {...rest}
        >
          <Icon style={{ width: width || "30px", height: height || "30px" }} />
          <span style={{ fontSize: size || ".8rem" }}>{label}</span>
        </button>
      </Whisper>
    );
  }

  return (
    <button
      className="btn d-flex flex-column justify-content-center align-items-center mx-2"
      onClick={onClick}
      {...rest}
    >
      <Icon style={{ width: width || "30px", height: height || "30px" }} />
      <span style={{ fontSize: size || ".8rem" }}>{label}</span>
    </button>
  );
};

export default ButtonIcon;
