/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ModalComponent from "../../components/Modal/Modal";
import PaginateComponent from "../../components/Paginate";
import Search from "../../components/Search";
import FormManageOrder from "../../forms/FormManageOrder";
import FormMassiveLoadWithoutReservation from "../../forms/FormMassiveLoadWithoutReservation";
import { _pushToastMessage } from "../../helpers";
import { createCsvSimple } from "../../helpers/csv";
import { _handleError } from "../../helpers/errors";
import { loading_off, loading_on } from "../../redux/actions/loader";
import OrderService from "../../services/orders.service";
import DefaultTemplate from "../../templates/DefaultTemplate";
import Filters from "./Filters";
import Header from "./Header";

import { TableComponent } from "./TableComponent";

const Orders = () => {
  const [data, setData] = useState<any>([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState<any>([]);
  const [showCode, setShowCode] = useState(false);
  const [state, setState] = useState<any>({});
  const [filters, setFilters] = useState<any>(null);
  const dispatch: any = useDispatch();
  const Order = new OrderService();

  useEffect(() => {
    setChecked(false);
    setIndeterminate(false);
    if (checkedKeys.length === data.data?.length) {
      setChecked(true);
    } else if (checkedKeys.length === 0) {
      setChecked(false);
    } else if (
      checkedKeys.length > 0 &&
      checkedKeys.length < data.data?.length
    ) {
      setIndeterminate(true);
    }
  }, [checkedKeys, data]);

  useEffect(() => {
    _getData();
  }, []);

  const _formatData = (data: any) => {
    data?.data?.map((item: any) => {
      const reservation: any = item.reservations[item.reservations.length - 1];
      item.code = reservation.code;
      item.url_image = reservation.url_qrcode;
      item.is_fail = item.errors && item.errors?.length > 0 ? true : false;
      return item;
    });
    setData(data);
  };

  const _getData = async (search: any = null) => {
    dispatch(loading_on());

    try {
      let response = null;
      if (search !== null) {
        response = await Order.list({ id: search, external_reference: search });
      } else {
        response = await Order.list();
      }

      response.data?.data?.map((item: any) => {
        const reservation: any =
          item.reservations[item.reservations.length - 1];
        item.code = reservation.code;
        item.url_image = reservation.url_qrcode;
        item.is_fail = item.errors && item.errors?.length > 0 ? true : false;
        return item;
      });

      setData(response.data);

      setState({
        ...state,
        orderModal: false,
        searchModal: false,
        massive_load: false,
        filters: false,
      });
      dispatch(loading_off());
    } catch (e: any) {
      dispatch(loading_off());
      _handleError(e, e.message);
    }
  };

  const _handleSelectChangeGlobal = async (payload: any) => {
    switch (payload.option) {
      case 1:
        await _exportToCsv();

        break;
      case 2:
        _pushToastMessage({
          type: "success",
          header: "Succeso",
          text: "¡Pronto!",
        });
        break;
      case 3:
        _pushToastMessage({
          type: "success",
          header: "Succeso",
          text: "¡Pronto!",
        });
        break;
      default:
        console.log(payload);
    }
  };

  const _exportToCsv = async () => {
    dispatch(loading_on);

    try {
      const responseGetCsvData: any = await Order.filterCsvData(filters || {});
      const dataCsv: any = responseGetCsvData.data;
      if (dataCsv.length === 0) {
        throw new Error(
          "Esta búsqueda no arrojó ningún resultado. Intente cambiar los filtros para mejorar la búsqueda."
        );
      }

      const headers: any = [
        "ID Orden",
        "Nombre",
        "Direccion",
        "Departamento",
        "Email",
        "Telefono",
        "Empresa",
        "Terminal",
        "Referencia externa",
        "Cajas",
        "Codigo",
        "Fecha Creación",
        "Estado",
        "Fecha Estado Actual",
      ];

      let csv: any = headers.join(",") + "\n";

      dataCsv.forEach((item: any) => {
        const { reservations, company, delivery_user, terminal } = item;
        const reservation: any = reservations[reservations.length - 1];
        let boxes: any = "";
        let code: any = "";
        let reservationDate: any = "";
        if (reservation) {
          boxes = reservation.boxes
            .map((box: any) => box.door_number)
            ?.join("-");
          code = reservation.code;
          reservationDate = moment(reservation.created_at).format(
            "DD/MM/YYYY hh:mm:ss"
          );
        }
        csv += `${item?.id},${delivery_user?.name},${delivery_user?.address || 'No informado'},${delivery_user?.apartment || 'No informado'},${delivery_user?.email},${
          delivery_user?.phone
        },${company.name},${terminal?.name},${item?.external_reference},${
          boxes || ""
        },${code},${moment(item.created_at).format("DD/MM/YYYY hh:mm:ss")},${
          item?.state
        },${reservationDate}`;
        csv += "\n";
      });

      createCsvSimple(csv, "orders");

      _pushToastMessage({
        type: "success",
        header: "Succeso",
        text: "Datos exportados con éxito...",
      });
      dispatch(loading_off);
    } catch (e: any) {
      _pushToastMessage({
        type: "error",
        header: "Error",
        text: e.message,
      });
      dispatch(loading_off);
    }
  };

  const _handleCheckAll = (value: any, checked: any) => {
    const keys: any = checked ? data.data.map((item: any) => item.id) : [];
    setCheckedKeys([...keys]);
  };

  const _handleCheck = (value: any, checked: any) => {
    const keys: any = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter((item: any) => item !== value);
    setCheckedKeys([...keys]);
  };

  const _toggleSearchModal = () =>
    setState({ ...state, searchModal: !state.searchModal });

  const _toggleOrderModal = (reload: any = false) => {
    setState({ ...state, orderModal: !state.orderModal });
    if (reload === true) _getData();
  };

  const _toggleFilters = () =>
    setState({
      ...state,
      filters: !state.filters,
    });

  const _toggleMassiveLoad = () =>
    setState({
      ...state,
      massive_load: !state.massive_load,
    });

  const _handleFilters = (data: any, params: any) => {
    _formatData(data);
    setFilters(params);
    console.log(params);
    _toggleFilters();
  };

  const _clearFilters = () => {
    setFilters(null);
    _getData();
  };

  return (
    <DefaultTemplate>
      <ModalComponent
        open={state.searchModal}
        size="md"
        title="Busca rapida"
        handleClose={_toggleSearchModal}
      >
        <Search
          handleSearch={(value: any) => _getData(value)}
          placeholder="Busca por el numero de la orden o referencia externa"
        />
      </ModalComponent>

      <ModalComponent
        open={state.orderModal}
        size="md"
        title="Order"
        handleClose={_toggleOrderModal}
      >
        <FormManageOrder />
      </ModalComponent>

      <ModalComponent
        open={state.filters}
        size="md"
        title="Filtros"
        handleClose={_toggleFilters}
      >
        <Filters
          handleCallback={_handleFilters}
          handleClose={_toggleFilters}
          filters={filters}
          clearFilters={_clearFilters}
        />
      </ModalComponent>

      <ModalComponent
        open={state.massive_load}
        size="lg"
        title="Carga Masiva"
        handleClose={_toggleMassiveLoad}
      >
        <FormMassiveLoadWithoutReservation
          handleCallback={_getData}
          handleClose={_toggleMassiveLoad}
        />
      </ModalComponent>

      <div className="row mx-0 px-4 py-5 with-header">
        <div className="default-template-box">
          <div className="row">
            <div className="col-12 justify-content-between align-items-center d-flex py-2 px-3 bold-300 line-border">
              <Header
                showCode={showCode}
                handleSelectChange={_handleSelectChangeGlobal}
                setShowCode={setShowCode}
                handleSearchModal={_toggleSearchModal}
                handleOrderModal={_toggleOrderModal}
                handleFilters={_toggleFilters}
                handleMassiveLoad={_toggleMassiveLoad}
                filters={filters}
              />
            </div>
            <div className="col-12 py-5">
              <TableComponent
                indeterminate={indeterminate}
                checked={checked}
                data={data}
                checkedKeys={checkedKeys}
                handleCheckAll={_handleCheckAll}
                handleCheck={_handleCheck}
                showCode={showCode}
                getData={_getData}
              />
            </div>
            <div className="col-12 text-center mb-5">
              <PaginateComponent data={data} setData={_formatData} />
            </div>
          </div>
        </div>
      </div>
    </DefaultTemplate>
  );
};

export default Orders;
