import { Input } from "rsuite";
import ErrorValidation from "../../components/ErrorValidation";

const TokenConection = (props: any) => {
  const { state, _handleOnChange, type } = props;
  return (
    <>
      <div className="col-12 mb-2">
        <label className="size-08 bold-300 ms-1">Url para envío</label>
        {type && type === "view" ? (
          <div className="size-08 ms-2">{state.provider_url}</div>
        ) : (
          <Input
            placeholder="Url para envío"
            value={state.provider_url}
            onChange={(value: any) =>
              _handleOnChange({
                target: { name: "provider_url", value },
              })
            }
          />
        )}
        <ErrorValidation
          className="text-end size-08"
          name="provider_url"
          validations={state.validations}
        />
      </div>

      <div className="col-12 mb-2">
        <label className="size-08 bold-300 ms-1">Token api</label>
        {type && type === "view" ? (
          <div className="size-08 ms-2">{state.secret}</div>
        ) : (
          <Input
            placeholder="Informa token de acceso"
            value={state.secret}
            onChange={(value: any) =>
              _handleOnChange({
                target: { name: "secret", value },
              })
            }
          />
        )}
        <ErrorValidation
          className="text-end size-08"
          name="secret"
          validations={state.validations}
        />
      </div>
    </>
  )
}

export default TokenConection