import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "rsuite";
import { _handleError } from "../../helpers/errors";
import { loading_off, loading_on } from "../../redux/actions/loader";
import PaginateService from "../../services/paginate.service";

const PaginateComponent = (props: any) => {
  const { data, setData } = props;
  const [disabled, setDisabled] = useState(false);
  const dispatch: any = useDispatch();
  const Paginate = new PaginateService();
  
  useEffect(() => {
    if (data?.next_page_url === null) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [data]);

  const _loadData = async () => {
    dispatch(loading_on());
    try {
      const response: any = await Paginate.get(data?.next_page_url);
      response.data.data.forEach((item: any) => {
        data.data.push(item);
      });
      const payload: any = { ...response.data, data: data.data };
      dispatch(loading_off());
      setData(payload);
    } catch (e: any) {
      _handleError(e, "No fue posible cargar la data");
      dispatch(loading_off());
    }
  };
  return (
    <Button appearance="subtle" disabled={disabled} onClick={_loadData}>
      Cargas más
    </Button>
  );
};

export default PaginateComponent;
