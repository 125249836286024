import { Fragment } from "react";
import { Dropdown, Popover } from "rsuite";

export const MoreMenu = (
  { className, left, top, onClose }: any,
  ref: any,
  speaker: any
) => {
  const { _handleOnClick, menu } = speaker;

  const _handleOnSelect = (eventKey: any) => {
    onClose();
    _handleOnClick(eventKey, speaker);
  };

  return (
    <Popover
      ref={ref}
      className={className}
      style={{ left, top, minWidth: "250px" }}
      full
    >
      <Dropdown.Menu onSelect={_handleOnSelect}>
        {menu &&
          menu.map((item: any, index: any) => (
            <Fragment key={`${item.label}-index`}>
              {item.show && (
                <Dropdown.Item
                  eventKey={item.eventKey !== undefined ? item.eventKey : index}
                >
                  <span className="bold-300 d-flex align-items-center">
                    <item.Icon
                      className="me-3"
                      style={{ width: "15px", height: "15px" }}
                    />
                    {item.label}
                  </span>
                </Dropdown.Item>
              )}
            </Fragment>
          ))}
      </Dropdown.Menu>
    </Popover>
  );
};
