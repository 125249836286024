export const createCsvSimple = (data: any, name: any = null) => {
  try {
    let csvContent = "data:text/csv;charset=utf-8,";
    const universalBOM: any = "\uFEFF";
    csvContent += universalBOM + data;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", name || "my_data.csv");
    document.body.appendChild(link);
    link.click();
  } catch (e: any) {
    console.log(e.message);
    return false;
  }
};
