export default interface IBox {
  name: string;
  width: string;
  height: string;
  depht: string;
}

export const attributes = {
  name: "Nombre",
  width: "Ancho",
  height: "Altura",
  depht: "Profundidad",
};
