import { FcFolder } from "react-icons/fc";
import "./styles.scss";
import { Link } from "react-router-dom";
const Folder = (props: any) => {
  const { label, url, link } = props;

  return (
    <div className="container-folder">
      {link ? (
        <Link to={url}>
          <FcFolder />
          {label}
        </Link>) : (
        <a href={url}>
          <FcFolder />
          {label}
        </a>
      )
      }
    </div >
  );
};

export default Folder;
