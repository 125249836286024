/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Input, Modal } from "rsuite";
import ErrorValidation from "../../../components/ErrorValidation";
import { loading_off, loading_on } from "../../../redux/actions/loader";
import DeliveryUserService from "../../../services/delivery_user.service";

const Filters = (props: any) => {
  const { handleClose, handleCallback, clearFilters } = props;
  const dispatch: any = useDispatch();
  const DeliveryUser = new DeliveryUserService();

  const [state, setState] = useState<any>({ validations: {}, inputs: {} });

  const _handleOnChange = ({ target }: any) => {
    const { name, value } = target;
    setState({ ...state, inputs: { ...state.inputs, [name]: value } });
  };

  useEffect(() => {
    if (props.filters) {
      if (props.filters.start)
        props.filters.start = moment(props.filters.start).toDate();
      if (props.filters.end)
        props.filters.end = moment(props.filters.end).toDate();
      setState({ ...state, inputs: { ...props.filters } });
    }
  }, []);

  const _handleSearch = async () => {
    dispatch(loading_on());
    try {
      const response: any = await DeliveryUser.filter(state.inputs);
      handleCallback(response.data, state.inputs);
      dispatch(loading_off());
    } catch (e: any) {
      console.log(e.message);
      dispatch(loading_off());
    }
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-6 mb-2">
          <label className="size-08 bold-300 ms-1">Nombre</label>
          <Input
            placeholder="Ingresa nombre"
            value={state.inputs.name}
            onChange={(value: any) =>
              _handleOnChange({ target: { name: "name", value } })
            }
          />
          <ErrorValidation
            name="name"
            className="size-08 text-end"
            validations={state.validations}
          />
        </div>

        <div className="col-6 mb-2">
          <label className="size-08 bold-300 ms-1">Email</label>
          <Input
            placeholder="Ingresa email"
            value={state.inputs.email}
            onChange={(value: any) =>
              _handleOnChange({ target: { name: "email", value } })
            }
          />
          <ErrorValidation
            name="email"
            className="size-08 text-end"
            validations={state.validations}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-6 mb-2">
          <label className="size-08 bold-300 ms-1">Empresa</label>
          <Input
            placeholder="Ingresa nombre de la empresa"
            value={state.inputs.company}
            onChange={(value: any) =>
              _handleOnChange({ target: { name: "company", value } })
            }
          />
          <ErrorValidation
            name="company"
            className="size-08 text-end"
            validations={state.validations}
          />
        </div>

        <div className="col-6 mb-2">
          <label className="size-08 bold-300 ms-1">Departamento</label>
          <Input
            placeholder="Ingresa numero departamento"
            value={state.inputs.apartment}
            onChange={(value: any) =>
              _handleOnChange({ target: { name: "apartment", value } })
            }
          />
          <ErrorValidation
            name="apartment"
            className="size-08 text-end"
            validations={state.validations}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 mb-2">
          <label className="size-08 bold-300 ms-1">Dirección</label>
          <Input
            placeholder="Ingresa dirección"
            value={state.inputs.address}
            onChange={(value: any) =>
              _handleOnChange({ target: { name: "address", value } })
            }
          />
          <ErrorValidation
            name="address"
            className="size-08 text-end"
            validations={state.validations}
          />
        </div>
      </div>

      <div className="row mt-4">
        <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            Cerrar
          </Button>
          <Button type="button" appearance="subtle" onClick={clearFilters}>
            Limpiar Filtros
          </Button>
          <Button type="button" appearance="primary" onClick={_handleSearch}>
            Confirmar
          </Button>
        </Modal.Footer>
      </div>
    </div>
  );
};

export default Filters;
