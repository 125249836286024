import * as yup from "yup";

export const DashboardSchema = yup.object().shape({
  company_id: yup.string().nullable().required("Este campo es requerido."),
  start: yup.date().nullable().required("Este campo es requerido."),
  end: yup.date().required("Este campo es requerido.").when("start", {
    is: (start: any) => {
      return start ? true : false;
    },
    then: yup.date().required("Este campo es requerido.").min(yup.ref("start"), "La fecha del campo hasta debe ser posterior a la fecha del campo desde."),
  }),
});

export type DashboardType = yup.InferType<typeof DashboardSchema>;
