export const templates: any = [
  {
    label: "Template Patrón",
    value: "delivery",
    colors: false,
  },
  {
    label: "Template Elock 1",
    value: "template-one",
    colors: true,
  },
  {
    label: "Template Elock 2",
    value: "template-two",
    colors: true,
  },
];
