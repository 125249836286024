/* eslint-disable react-hooks/exhaustive-deps */
import Folder from "../../components/Folder";
import DefaultTemplate from "../../templates/DefaultTemplate";


const Documentation = () => {
  return (
    <DefaultTemplate>
      <div className="row mx-0 px-4 py-5 with-header">
        <div className="default-template-box">
          <div className="row">
            <div className="col-12 justify-content-between align-items-center d-flex bold-300 line-border py-3">
              Documentación
            </div>

            <div className="col justify-content-start p-3">
              <div>
                <Folder label="Api externa" url="/documentation/doc.html" />
              </div>
            </div>
            <div className="col justify-content-start p-3">
              <div>
                <Folder label="Usuarios" url="/documentation/users" link />
              </div>
            </div>
            <div className="col justify-content-start p-3">
              <div>
                <Folder label="Modulos" url="/documentation/modules" link />
              </div>
            </div>
            <div className="col justify-content-start p-3">
              <div>
                <Folder label="Postman" url="/documentation/postman" link />
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultTemplate>
  );
};

export default Documentation;
