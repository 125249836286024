export const comdoors:any = [
    {
        value: "COM1",
        label: "COM1"
    },
    {
        value: "COM2",
        label: "COM2"
    },
    {
        value: "COM3",
        label: "COM3"
    },
    {
        value: "COM4",
        label: "COM4"
    },
    {
        value: "COM5",
        label: "COM5"
    },
    {
        value: "COM6",
        label: "COM6"
    },
    {
        value: "COM7",
        label: "COM7"
    },
    {
        value: "COM8",
        label: "COM8"
    },
    {
        value: "COM9",
        label: "COM9"
    },
];