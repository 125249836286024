import { Tooltip, Whisper } from 'rsuite';

const WhisperComponent = (props: any) => {
  const { text, children, capitalizeOff, align, width = "100%" } = props;
  return (
    <Whisper trigger="hover" placement="auto" controlId={`control-id-auto`} speaker={<Tooltip>{text || ''}</Tooltip>}>
      <div style={{textTransform: capitalizeOff ? 'none' : 'capitalize', width: width, overflow: 'hidden', textOverflow: 'ellipsis', textAlign: align || 'center' }}>{children}</div>
    </Whisper>
  );
};

export default WhisperComponent;
