/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Input, Modal } from "rsuite";
import ErrorValidation from "../../components/ErrorValidation";
import UploadImage from "../../components/UploadImage";
import { _pushToastMessage } from "../../helpers";
import { _handleError } from "../../helpers/errors";
import validate from "../../helpers/validate";
import { loading_off, loading_on } from "../../redux/actions/loader";
import CourierService from "../../services/courier.service";
import { CourierSchema } from "../../validations/courier.validations";

const FormCourier = (props: any) => {
  const { type, data, handleClose, getData } = props;
  const [state, setState] = useState<any>({
    name: "",
    logo: "",
    validations: {},
  });

  const Courier = new CourierService();
  const dispatch: any = useDispatch();

  useEffect(() => {
    if (type === "update") {
      data.logo = "";
      setState({ ...state, ...data });
    }

    if (type === "view") {
      setState({ ...state, ...data });
    }
  }, []);

  const _handleOnChange = ({ target }: any) => {
    const { name, value } = target;
    state[name] = value;
    setState({ ...state });
  };

  const _handleOnSubmit = async (e: any) => {
    e.preventDefault();

    if (type === "view") {
      handleClose();
      return;
    }

    const validations: any = await validate(CourierSchema, state);

    setState({ ...state, validations });

    if (validations) {
      return;
    }

    dispatch(loading_on());

    const payload: any = {
      name: state.name,
      logo: state.logo,
    };

    try {
      if (type === "create") {
        await Courier.create(payload);
      }

      if (type === "update") {
        payload.id = state.id;
        await Courier.update(payload);
      }

      _pushToastMessage({
        type: "success",
        header: "Succeso",
        text: `Courier ${
          type === "create" ? "creado" : "actualizado"
        } con éxito...`,
      });

      getData();
    } catch (e: any) {
      dispatch(loading_off());
      _handleError(e, e.message);
    }
  };

  return (
    <div className="container-fluid">
      <form onSubmit={_handleOnSubmit}>
        <div className="row">
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Nombre</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.name}</div>
            ) : (
              <Input
                placeholder="Nombre"
                value={state.name}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "name", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="name"
              validations={state.validations}
            />
          </div>

          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Nombre</label>
            <UploadImage
              onChange={(value: any) =>
                _handleOnChange({ target: { name: "logo", value } })
              }
              onError={console.log}
            />
          </div>
        </div>
        <div className="row mt-4">
          <Modal.Footer>
            <Button onClick={handleClose} appearance="subtle">
              Cerrar
            </Button>
            <Button type="submit" appearance="primary">
              Confirmar
            </Button>
          </Modal.Footer>
        </div>
      </form>
    </div>
  );
};

export default FormCourier;
