const Webhook = ({handleOnChange, value}:any) => {
    console.log("value", value);
    return (
        <div className="col-12 size-08">
            <hr/>
            <span className="bold">Webhook</span>
            <input type="text" className="form-control" name="webhook" value={value} onChange={handleOnChange} placeholder="Ingresa url del webhook" />
        </div>
    );
}

export default Webhook;