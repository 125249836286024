export default interface ICompany {
  name: string,
  address: string,
  email: string,
  phone: string,
  type: string,
}

export const attributes = {
  name: "Nombre",
  address: "Dirección",
  email: "Email",
  phone: "Telefóno",
  company_type: "Tipo",
};
