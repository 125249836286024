import moment from "moment";
import "./styles.scss";

const HistoryOrder = (props: any) => {
  const { data, reservation_types } = props;

  
  return (
    <div className="container-fluid">
      <div className="row size-08 mb-4 history-data">
        <div className="col-6 p-3">
          <div className="bold">Numero Orden</div>
          <span>#{data.id}</span>
        </div>
        <div className="col-6 p-3">
          <div className="bold">Nombre</div>
          <span>{data.delivery_user?.name}</span>
        </div>
        <div className="col-6 p-3">
          <div className="bold">Terminal</div>
          <span>{data.terminal?.username}</span>
        </div>
        <div className="col-6 p-3">
          <div className="bold">Empresa</div>
          <span>{data.company?.name}</span>
        </div>
      </div>

      <div className="row border-bottom px-3 mb-3 mx-2 size-09">Histórico</div>
      <div className="row history-component">
        {data?.reservations?.map((item: any) => (
          <div className="col-12 history-component__history-item" key={item.id}>
            <span>
              {
                reservation_types.find(
                  (rt: any) => rt.value === item.reservation_type
                )?.label
              }
            </span>
            <span>{moment(item.start_at).format('DD/MM/YYYY H:mm:ss')}</span>
            <span>{`Caja(s): ${item.boxes?.map((box:any) => box.door_number)?.join(' , ')}`} </span>
            <span className="bold-300">Código: {item.code}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HistoryOrder;
