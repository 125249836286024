import { Fragment, useEffect, useState } from "react";
import { Checkbox, Divider, Table, Tooltip, Whisper } from "rsuite";
import ParagraphIcon from "@rsuite/icons/Paragraph";
import MoreIcon from "@rsuite/icons/More";
import { MoreMenu } from "../../../components/MoreMenu";
import { _actionMenuSpeaker } from "../speaker";
import { attributes } from "../Schema";
import ModalComponent from "../../../components/Modal/Modal";
import FormUser from "../../../forms/FormUser";
import moment from "moment";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { useDispatch } from "react-redux";
import { loading_off, loading_on } from "../../../redux/actions/loader";
import { _pushToastMessage } from "../../../helpers";
import { _handleError } from "../../../helpers/errors";
import UserService from "../../../services/user.service";
import { useSelector } from "react-redux";
import MasterKeyForm from "../../../forms/MasterKeyForm";
import FormExternalKey from "../../../forms/FormExternalKey";
import FormUpdatePassword from "../../../forms/FormUpdatePassword";

export const TableComponent = (props: any) => {
  const {
    data,
    getData,
    indeterminate,
    checked,
    handleCheck,
    handleCheckAll,
    checkedKeys,
    roles,
  } = props;

  const { loader } = useSelector((state: any) => state);

  const dispatch: any = useDispatch();
  const [tableHeader, setTableHeader] = useState<any>([]);
  const [state, setState] = useState<any>({
    updateUserModal: false,
    viewUserModal: false,
    selectedData: {},
  });
  const [confirmation, setConfirmation] = useState<any>({
    open: false,
    text: "",
    handleClose: () => {},
    handleConfirm: () => {},
  });

  const [masterKeyData, setMasterKeyData] = useState<any>({
    open: false,
    data: {},
  });

  const [externalKeyData, setExternalKeyData] = useState<any>({
    open: false,
    data: {},
  });

  useEffect(() => {
    const fields: any = { ...attributes };
    const tableHeader: any = Object.keys(fields).map((key: any) => {
      return {
        label: fields[key],
        key: key,
        align: "left",
        alignFlex: "flex-start",
        flexGrow: 1,
      };
    });
    setTableHeader(tableHeader);
  }, []);

  const handleSelectChange = (payload: any) => {
    let selectedData: any = null;
    switch (payload.option) {
      case 1:
        selectedData = data.data.find((item: any) => item.id === payload.id);
        setState({ ...state, updateUserModal: true, selectedData });
        break;
      case 2:
        selectedData = data.data.find((item: any) => item.id === payload.id);
        setState({ ...state, viewUserModal: true, selectedData });
        break;
      case 3:
        setConfirmation({
          open: true,
          text: "¿Seguro que quieres eliminar este usuario",
          handleClose: () => setConfirmation({ ...confirmation, open: false }),
          handleConfirm: () => _deleteUser(payload.id),
        });
        break;
      case 4:
        const dataUser: any = data.data?.find(
          (item: any) => item.id === payload.id
        );
        setMasterKeyData({ ...masterKeyData, data: dataUser, open: true });
        break;

      case 5:
        const externalUser: any = data.data?.find(
          (item: any) => item.id === payload.id
        );
        setExternalKeyData({
          ...externalKeyData,
          data: externalUser,
          open: true,
        });
        break;
      case 6:
        selectedData = data.data?.find((item: any) => item.id === payload.id);
        setState({ ...state, viewUpdatePassword: true, selectedData });
        break;
      default:
        console.log(payload);
    }
  };

  const _deleteUser = async (id: any) => {
    dispatch(loading_on());
    const User: any = new UserService();
    try {
      await User.delete(id);
      _pushToastMessage({
        type: "success",
        header: "Éxito",
        text: "Usuario eliminado con éxito",
      });
      setConfirmation({ ...confirmation, open: false });
      getData();
      dispatch(loading_off());
    } catch (e: any) {
      _handleError(e, "No fue posible eliminar este usuario");
      setConfirmation({ ...confirmation, open: false });
      dispatch(loading_off());
    }
  };

  const _toggleUpdateUserModal = (getUserData: any = false) => {
    setState({ ...state, updateUserModal: !state.updateUserModal });
    if (getUserData) {
      getData();
    }
  };

  const _toggleUpdatePasswordModal = (getUserData: any = false) => {
    setState({ ...state, viewUpdatePassword: !state.viewUpdatePassword });
    if (getUserData) {
      getData();
    }
  };

  const _toggleViewUserModal = () =>
    setState({ ...state, viewUserModal: !state.viewUserModal });

  const Actions = (props: any) => {
    const { payload } = props;
    const _speaker: any = _actionMenuSpeaker(payload, handleSelectChange);

    return (
      <div className="row me-3">
        <div className="col-12">
          <button
            className="btn px-0"
            onClick={() => handleSelectChange({ option: 2, id: payload.id })}
          >
            <ParagraphIcon />
          </button>
          <Divider vertical />
          <span style={{ cursor: "pointer" }}>
            <Whisper
              controlId={payload.id}
              placement="auto"
              trigger="click"
              speaker={(whisper_payload: any, ref: any) =>
                MoreMenu(whisper_payload, ref, _speaker)
              }
            >
              <MoreIcon />
            </Whisper>
          </span>
        </div>
      </div>
    );
  };

  const _handleConfirmMasterKey = () => {
    setMasterKeyData({ ...masterKeyData, open: false });
    getData();
  };

  const _handleConfirmExternalKey = () => {
    setExternalKeyData({ ...externalKeyData, open: false });
    getData();
  };

  return (
    <Fragment>
      <ModalComponent
        open={state.updateUserModal}
        size="md"
        title="Actualizar usuario"
        handleClose={_toggleUpdateUserModal}
      >
        <FormUser type="update" data={state.selectedData} roles={roles} />
      </ModalComponent>

      <ModalComponent
        open={state.viewUpdatePassword}
        size="md"
        title="Actualizar contraseña"
        handleClose={_toggleUpdatePasswordModal}
      >
        <FormUpdatePassword type="update" data={state.selectedData} roles={roles} />
      </ModalComponent>

      <ModalComponent
        open={state.viewUserModal}
        size="sm"
        title="Usuario"
        handleClose={_toggleViewUserModal}
      >
        <FormUser type="view" data={state.selectedData} />
      </ModalComponent>

      <ModalComponent
        open={confirmation.open}
        size="md"
        title="Eliminar usuario"
        handleClose={() => confirmation.handleClose()}
      >
        <ConfirmationModal
          text={confirmation.text}
          handleConfirm={confirmation.handleConfirm}
        />
      </ModalComponent>

      <ModalComponent
        open={masterKeyData.open}
        size="md"
        title="MasterKey"
        handleClose={() => setMasterKeyData({ ...masterKeyData, open: false })}
      >
        <MasterKeyForm
          {...masterKeyData}
          handleConfirm={_handleConfirmMasterKey}
        />
      </ModalComponent>

      <ModalComponent
        open={externalKeyData.open}
        size="md"
        title="Api Keys"
        handleClose={() =>
          setExternalKeyData({ ...externalKeyData, open: false })
        }
      >
        <FormExternalKey
          {...externalKeyData}
          handleConfirm={_handleConfirmExternalKey}
        />
      </ModalComponent>

      <Table
        data={data.data}
        rowClassName="striped"
        autoHeight
        rowHeight={80}
        loading={loader.is_loading}
        locale={{ emptyMessage: "No encontramos ningún dato." }}
      >
        <Table.Column align="center" verticalAlign="middle">
          <Table.HeaderCell
            verticalAlign="middle"
            align="center"
            style={{ padding: 0 }}
          >
            <Checkbox
              onChange={handleCheckAll}
              indeterminate={indeterminate}
              checked={checked}
            />
          </Table.HeaderCell>
          <Table.Cell>
            {(rowData) => {
              return (
                <Checkbox
                  onChange={handleCheck}
                  value={rowData.id}
                  checked={checkedKeys.some((item: any) => item === rowData.id)}
                />
              );
            }}
          </Table.Cell>
        </Table.Column>

        {tableHeader &&
          tableHeader.map((column: any, index: any) => (
            <Table.Column
              align={column.align}
              flexGrow={column.flexGrow}
              key={`table-column-${index}`}
            >
              <Table.HeaderCell>
                <span className="bold" style={{ textTransform: "capitalize" }}>
                  {column.label}
                </span>
              </Table.HeaderCell>

              <Table.Cell
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: column.alignFlex,
                }}
              >
                {(rowData) => {
                  switch (column.key) {
                    case "created_at":
                      return (
                        <Whisper
                          trigger="hover"
                          placement="auto"
                          controlId={`control-id-auto`}
                          speaker={
                            <Tooltip>
                              {moment(rowData[column.key]).format("DD-MM-YYYY")}
                            </Tooltip>
                          }
                        >
                          <div
                            style={{
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontSize: ".9rem",
                            }}
                          >
                            {moment(rowData[column.key]).format("DD-MM-YYYY")}
                          </div>
                        </Whisper>
                      );
                    case "roles":
                      return (
                        <Whisper
                          trigger="hover"
                          placement="auto"
                          controlId={`control-id-auto`}
                          speaker={<Tooltip>{rowData.roles[0]?.label}</Tooltip>}
                        >
                          <div
                            style={{
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontSize: ".9rem",
                            }}
                          >
                            {rowData.roles[0]?.label}
                          </div>
                        </Whisper>
                      );
                    default:
                      return (
                        <Whisper
                          trigger="hover"
                          placement="auto"
                          controlId={`control-id-auto`}
                          speaker={<Tooltip>{rowData[column.key]}</Tooltip>}
                        >
                          <div
                            style={{
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontSize: ".9rem",
                            }}
                          >
                            {rowData[column.key]}
                          </div>
                        </Whisper>
                      );
                  }
                }}
              </Table.Cell>
            </Table.Column>
          ))}

        <Table.Column align="right" flexGrow={1} verticalAlign="middle">
          <Table.HeaderCell>{""}</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => {
              return <Actions payload={rowData} />;
            }}
          </Table.Cell>
        </Table.Column>
      </Table>
    </Fragment>
  );
};
