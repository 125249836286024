/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Button,
  Input,
  MaskedInput,
  Modal,
  SelectPicker,
  TagPicker,
} from "rsuite";
import ErrorValidation from "../../components/ErrorValidation";
import { _pushToastMessage } from "../../helpers";
import { _handleError } from "../../helpers/errors";
import { phoneMask } from "../../helpers/masks";
import { regexCognito } from "../../helpers/utils";
import validate from "../../helpers/validate";
import { loading_off, loading_on } from "../../redux/actions/loader";
import CompanyService from "../../services/company.service";
import RoleService from "../../services/roles.service";
import UserService from "../../services/user.service";
import { UserSchema } from "../../validations/user.validations";

const FormUser = (props: any) => {
  const { type, data, roles, handleClose } = props;
  const [state, setState] = useState<any>({
    name: "",
    phone: "",
    address: "",
    rut: "",
    email: "",
    password: "",
    cpassword: "",
    role_id: null,
    company_id: null,
    validations: {},
  });
  const {session} = useSelector((state:any) => state);
  const [companies, setCompanies] = useState<any>([]);
  const [rolesCompany, setRolesCompany] = useState<any>([]);
  const dispatch: any = useDispatch();

  const User = new UserService();
  const Company = new CompanyService();
  const Roles = new RoleService();

  useEffect(() => {
    if (type !== "create" && type !== "create-by-company") {
      data.role_id = data.roles?.length > 0 ? data.roles[0].id : null;
      data.company_id = data.companies?.map((item: any) => item.id);
      setState({ ...state, ...data });
    }

    if (type === "create" || type === "update") {
      _getCompanies();
    }
  }, []);

  const _getCompanies = async () => {
    dispatch(loading_on());
    try {
      const response: any = await Company.selectList();
      const rolesResponse: any = await Roles.list();
      setCompanies(response.data);
      setRolesCompany(
        rolesResponse.data.map((item: any) => {
          return { value: item.id, label: item.label };
        })
      );
      dispatch(loading_off());
    } catch (e: any) {
      _handleError(e, e.message);
      dispatch(loading_off());
    }
  };

  const _handleOnChange = ({ target }: any) => {
    const { name, value } = target;
    state[name] = value;
    setState({ ...state });
  };

  const _handleOnSubmit = async (e: any) => {
    e.preventDefault();

    if (type === "view") {
      handleClose();
      return;
    }

    let validations: any = await validate(UserSchema, state);

    if (type === "create" || type === "create-by-company") {
      if (!regexCognito(state.password)) {
        validations = validations || {};
        validations.password = [
          "La contraseña debe tener al menos 8 caracteres, 1 letra mayúscula, 1 letra minúscula, 1 carácter especial",
        ];
      }

      if (state.password !== state.cpassword) {
        validations = validations || {};
        validations.password = ["Las contraseñas deben ser las mismas"];
        validations.cpassword = ["Las contraseñas deben ser las mismas"];
      }
    }

    if (type === "create-by-company") {
      if (!state.company_id) {
        validations = validations || {};
        validations.company_id = ["Este campo es requerido"];
      }
    }

    setState({ ...state, validations });

    if (validations !== false) {
      return;
    }

    dispatch(loading_on());

    if (type === "create" || type === "create-by-company") {
      try {
        await User.create(state);
        _pushToastMessage({
          type: "success",
          header: "Succeso",
          text: "Usuario creado con éxito",
        });
        handleClose(true);
      } catch (e: any) {
        _handleError(e, "No fue posible crear el usuario");
        if (e.response?.status === 422) {
          setState({ ...state, validations: e.response.data });
        }
        dispatch(loading_off());
        return;
      }
    }

    if (type === "update") {
      try {
        await User.update(state);
        _pushToastMessage({
          type: "success",
          header: "Succeso",
          text: "Usuario actualizado con éxito",
        });
        handleClose(true);
      } catch (e: any) {
        _handleError(e, "No fue posible actualizar el usuario");
        if (e.response?.status === 422) {
          setState({ ...state, validations: e.response.data });
        }
        dispatch(loading_off());
        return;
      }
    }

    setState({ ...state, validations: {} });
    // handleClose();
  };

  return (
    <div className="container-fluid">
      <form onSubmit={_handleOnSubmit}>
        <div className="row">
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Nombre</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.name}</div>
            ) : (
              <Input
                placeholder="Nombre"
                value={state.name}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "name", value } })
                }
              />
            )}
            <div className="text-end size-08">
              <ErrorValidation validations={state.validations} name="name" />
            </div>
          </div>
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Telefóno</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.phone}</div>
            ) : (
              <MaskedInput
                mask={phoneMask}
                value={state.phone}
                guide={true}
                showMask={true}
                keepCharPositions={true}
                placeholder={""}
                placeholderChar={"_"}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "phone", value } })
                }
              />
            )}
            <div className="text-end size-08">
              <ErrorValidation validations={state.validations} name="phone" />
            </div>
          </div>
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Dirección</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.address}</div>
            ) : (
              <Input
                placeholder="Dirección"
                value={state.address}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "address", value } })
                }
              />
            )}
            <div className="text-end size-08">
              <ErrorValidation validations={state.validations} name="address" />
            </div>
          </div>

          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Email</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2">{state.email}</div>
            ) : (
              <Input
                placeholder="Email"
                value={state.email}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "email", value } })
                }
              />
            )}
            <div className="text-end size-08">
              <ErrorValidation validations={state.validations} name="email" />
            </div>
          </div>

          <div
            className={`col-6 mb-2 ${
              type === "update" || type === "view" ? "d-none" : ""
            }`}
          >
            <label className="size-08 bold-300 ms-1">Contraseña</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.password}</div>
            ) : (
              <Input
                placeholder="**********"
                value={state.password}
                type="password"
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "password", value } })
                }
              />
            )}
            <div className="text-end size-08">
              <ErrorValidation
                validations={state.validations}
                name="password"
              />
            </div>
          </div>

          <div
            className={`col-6 mb-2 ${
              type === "update" || type === "view" ? "d-none" : ""
            }`}
          >
            <label className="size-08 bold-300 ms-1">
              Confirmar contraseña
            </label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.cpassword}</div>
            ) : (
              <Input
                placeholder="**********"
                value={state.cpassword}
                type="password"
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "cpassword", value } })
                }
              />
            )}
            <div className="text-end size-08">
              <ErrorValidation
                validations={state.validations}
                name="cpassword"
              />
            </div>
          </div>

          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Rut</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.rut}</div>
            ) : (
              <Input
                placeholder="11.111.111-1"
                value={state.rut}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "rut", value } })
                }
              />
            )}
            <div className="text-end size-08">
              <ErrorValidation validations={state.validations} name="rut" />
            </div>
          </div>

          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Role</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.roles?.length > 0 ? state.roles[0]?.label : ""}
              </div>
            ) : (
              <SelectPicker
                className="w-100"
                locale={{
                  placeholder: "Selecciona una role",
                  searchPlaceholder: "Buscar",
                  emptyMessage: "Ninguna role encontrada",
                  noResultsText: "Ninguna role encontrada",
                }}
                cleanable={false}
                data={roles || rolesCompany}
                value={state.role_id}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "role_id", value } })
                }
                disabled={state.id === session?.profile?.id}
              />
            )}
            <div className="text-end size-08">
              <ErrorValidation validations={state.validations} name="role_id" />
            </div>
          </div>
          {type && (type === "create" || type === "update") && (
            <div className="col-12 mb-2">
              <label className="size-08 bold-300 ms-1">Empresa</label>
              {type && type === "view" ? (
                <div className="size-08 ms-2 capitalize">{state.company}</div>
              ) : (
                <TagPicker
                  className="w-100"
                  locale={{
                    placeholder: "Selecciona una empresa",
                    searchPlaceholder: "Buscar",
                    emptyMessage: "Ninguna empresa encontrada",
                    noResultsText: "Ninguna empresa encontrada",
                  }}
                  data={companies}
                  value={state.company_id}
                  onChange={(value: any) =>
                    _handleOnChange({ target: { name: "company_id", value } })
                  }
                />
              )}
              <div className="text-end size-08">
                <ErrorValidation
                  validations={state.validations}
                  name="company_id"
                />
              </div>
            </div>
          )}
        </div>
        <div className="row mt-4">
          <Modal.Footer>
            <Button onClick={() => handleClose(false)} appearance="subtle">
              Cancelar
            </Button>
            <Button type="submit" appearance="primary">
              Confirmar
            </Button>
          </Modal.Footer>
        </div>
      </form>
    </div>
  );
};

export default FormUser;
