export const modules = [
  {
    id: 1,
    name: "Modulo Residencial",
    desc: "El módulo residencial consta de un modelo básico de entrega y retiro. Una persona hace una entrega, informando nombre o número de apartamento. Y después de la entrega, el residente puede realizar el retiro informando un código.",
    code: "residential-module",
  },
  {
    id: 2,
    name: "Modulo Pickup",
    desc: "El módulo de pickup consta de un modelo donde primero se hace una reserva, y luego, con el código de reserva, se hace la entrega. La persona para la que se realiza la reserva recibe un código de retiro y con el código puede retirar su pedido.",
    code: "pickup-module",
  },
  {
    id: 3,
    name: "Modulo Pickup Box",
    desc: "El módulo Pick up Box consiste en un modelo donde primero se crea una reserva, informando los datos de quién recibirá y qué se entregará. Luego el responsable se dirige a una terminal y realiza la entrega, buscando por nombre o producto. Despues de la entrega, la persona puede realizar el retiro informando un código.",
    code: "pickup-box-module",
  },
  {
    id: 4,
    name: "Modulo Lider",
    desc: "Lo mismo que modelo pickup, pero con un diseño de Lider.",
    code: "lider-module",
  },
  {
    id: 5,
    name: "Modulo Unimarc",
    desc: "Lo mismo que modelo pickup, pero con un diseño de Unimarc",
    code: "unimarc-module",
  },
  {
    id: 6,
    name: "Modulo Las Condes",
    desc: "Lo mismo que modelo pickup, pero con un diseño de la Municipalidad Las Condes",
    code: "lascondes-module",
  },
];
