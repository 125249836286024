/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Input, Modal } from "rsuite";
import ErrorValidation from "../../components/ErrorValidation";
import { _pushToastMessage } from "../../helpers";
import { _handleError } from "../../helpers/errors";
import { regexCognito } from "../../helpers/utils";
import { loading_off, loading_on } from "../../redux/actions/loader";
import UserService from "../../services/user.service";

const FormUpdatePassword = (props: any) => {
  const { type, data, handleClose } = props;
  const [state, setState] = useState<any>({
    password: "",
    cpassword: "",
    validations: {},
  });

  const dispatch: any = useDispatch();
  const User = new UserService();

  useEffect(() => {
    if (type === "update") {
      data.logo = "";
      setState({ ...state, ...data });
    }

    if (type === "view") {
      setState({ ...state, ...data });
    }
  }, []);

  const _handleOnChange = ({ target }: any) => {
    const { name, value } = target;
    state[name] = value;
    setState({ ...state });
  };

  const _handleOnSubmit = async (e: any) => {
    e.preventDefault();

    let validations: any = false;

    if (!regexCognito(state.password)) {
      validations = validations || {};
      validations.password = [
        "La contraseña debe tener al menos 8 caracteres, 1 letra mayúscula, 1 letra minúscula, 1 carácter especial",
      ];
    }

    if (state.password !== state.cpassword) {
      validations = validations || {};
      validations.password = ["Las contraseñas deben ser las mismas"];
      validations.cpassword = ["Las contraseñas deben ser las mismas"];
    }

    setState({ ...state, validations });

    if (validations !== false) {
      return;
    }

    dispatch(loading_on());

    const payload: any = {
      password: state.password,
      id: data.id,
    };

    try {
      await User.updatePassword(payload);
      _pushToastMessage({
        type: "success",
        header: "Succeso",
        text: `Contraseña actualizada con éxito...`,
      });

      handleClose(true);
    } catch (e: any) {
      dispatch(loading_off());
      _handleError(e, e.message);
    }
  };

  return (
    <div className="container-fluid">
      <form onSubmit={_handleOnSubmit}>
        <div className="row">
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Nueva contraseña</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.name}</div>
            ) : (
              <Input
                placeholder="Contraseña"
                value={state.password}
                type="password"
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "password", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="password"
              validations={state.validations}
            />
          </div>
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">
              Confirmar nueva contraseña
            </label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.name}</div>
            ) : (
              <Input
                placeholder="Confirmar contraseña"
                value={state.cpassword}
                type="password"
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "cpassword", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="cpassword"
              validations={state.validations}
            />
          </div>
        </div>
        <div className="row mt-4">
          <Modal.Footer>
            <Button onClick={handleClose} appearance="subtle">
              Cerrar
            </Button>
            <Button type="submit" appearance="primary">
              Confirmar
            </Button>
          </Modal.Footer>
        </div>
      </form>
    </div>
  );
};

export default FormUpdatePassword;
