import { Tooltip, Whisper } from "rsuite";

const WhisperTooltip = (props: any) => {
  const { data, className, style } = props;
  return (
    <Whisper
      trigger="hover"
      placement="auto"
      controlId={`control-id-auto`}
      speaker={<Tooltip>{data}</Tooltip>}
    >
      <div style={style} className={className}>
        {data}
      </div>
    </Whisper>
  );
};

export default WhisperTooltip;
