import { Button, Modal } from "rsuite";

const ConfirmationModal = (props: any) => {
  const { handleClose, handleConfirm, text } = props;

  return (
    <div className="row w-100 px-4">
      {text}
      <div className="col-12 mt-4">
        <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            Cerrar
          </Button>
          <Button type="button" onClick={handleConfirm} appearance="primary">
            Confirmar
          </Button>
        </Modal.Footer>
      </div>
    </div>
  );
};

export default ConfirmationModal;
