import EditIcon from "@rsuite/icons/Edit";
import ParagraphIcon from "@rsuite/icons/Paragraph";
import TrashIcon from "@rsuite/icons/Trash";
import DragableIcon from '@rsuite/icons/Dragable';

export const _actionMenuSpeaker = (data: any, _handleOnSelect: any) => {
  console.log(data)
  return {
    menu: [
      {
        label: "Detalles",
        Icon: ParagraphIcon,
        show: true,
        eventKey: 2,
      },
      {
        label: "Editar",
        Icon: EditIcon,
        show: true,
        eventKey: 1,
      },
      {
        label: "Eliminar",
        Icon: TrashIcon,
        show: true,
        eventKey: 3,
      },
      {
        label: data?.is_active === 1 ? 'Desactivar' : "Activar",
        Icon: DragableIcon,
        show: true,
        eventKey: 4,
      },
    ],
    _handleOnClick: (eventKey: any, _speaker: any) =>
      _handleOnSelect({ option: eventKey, id: data.id }),
  };
};

export const _actionMenuSpeakerAll = (_handleOnSelect: any) => {
  return {
    menu: [
      {
        label: "Exportar",
        Icon: ParagraphIcon,
        show: true,
        eventKey: 1,
      },
      {
        label: "Eliminar seleccionados",
        Icon: TrashIcon,
        show: true,
        eventKey: 2,
      },
    ],
    _handleOnClick: (eventKey: any, _speaker: any) =>
      _handleOnSelect({ option: eventKey }),
  };
};
