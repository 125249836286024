import PeoplesIcon from "@rsuite/icons/Peoples";
import MenuIcon from "@rsuite/icons/Menu";
import { useSelector } from "react-redux";
const Header = () => {
  const { session } = useSelector((state: any) => state);
  return (
    <div className="default-template-header">
      <div className="d-block d-sm-none">
        <MenuIcon className="me-3 people-icon" />
      </div>
      <div className="d-flex align-items-center">
        <PeoplesIcon className="me-3 people-icon" />
        <div className="d-flex flex-column justify-content-center">
          <span className="size-09">{session.profile?.name}</span>
          <span className="size-08">
            {session.profile?.roles?.length > 0
              ? session.profile?.roles[0]?.label
              : ""}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Header;
