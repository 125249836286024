const validate = (schema: any, inputs: any, callback: any = null) => {
  return schema.validate(inputs, { abortEarly: false })
    .then(() => {
      if (callback) {
        callback()
      } else {
        console.log('Submit Form');
      }
      return false
    }).catch((error: any) => {
      const validationErrors: any = {}
      if (error && error.inner) {
        error.inner.forEach((errorList: any) => {
          validationErrors[errorList.path] = errorList.errors
        })
      } else {
        console.log(error)
      }
      return validationErrors
    })
}

export default validate