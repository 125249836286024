import DefaultTemplate from "../../templates/DefaultTemplate";
import ModuleCard from "./ModuleCard";
import { modules } from "./modules";

const ModulesDoc = () => {
    return (
        <DefaultTemplate>
            <div className="row mx-0 px-4 py-5 with-header">
                {modules.map((module: any) => (
                    <ModuleCard {...module} />
                ))}
            </div>
        </DefaultTemplate>
    );
}

export default ModulesDoc;

/**
 * 
 * @modules
 * 
 * residential-module: Modulo de entrega residencial.
 *    Primero se crea un usuario, y este usuario queda disponible para ser usado en el modulo de entrega residencial.
 * 
 * pickup-module: Modulo de entrega con caja reservada
 *    Al momento de crear la orden, se inicia una reserva, crea el usuario y hace la reserva de la(s) caja(s).
 * 
 * pickup-box-module: Modulo de entrega sin caja reservada
 *    Al momento de crear la orden, se inicia una reserva, crea el usuario, pero no hace la reserva de una o mas cajas.
 *    Se selecciona la caja al momento de hacer la entrega
 * 
 * lider-module: 
 *    Mismo que modulo `pickup-module`, pero con la diferencia que tiene el template de lider.
 * 
 * unimarc-module:
 *    Mismo que modulo `pickup-module`, pero con la diferencia que tiene el template de unimarc.
 * 
 * lascondes-module:
 *    Mismo que modulo `pickup-module`, pero con la diferencia que tiene el template de la municipalidad las condes.
 */