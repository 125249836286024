/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SelectPicker, Toggle } from "rsuite";
import ErrorValidation from "../../components/ErrorValidation";
import { _pushToastMessage } from "../../helpers";
import { _handleError } from "../../helpers/errors";
import validate from "../../helpers/validate";
import {
  loading_off,
  loading_on,
  set_loader,
} from "../../redux/actions/loader";
import BoxService from "../../services/box.service";
import CompanyService from "../../services/company.service";
import DeliveryUserService from "../../services/delivery_user.service";
import OrderService from "../../services/orders.service";
import { OrderEcommerceSchema } from "../../validations/order_ecommerce.validations";
import { OrderPickupSchema } from "../../validations/order_pickup.validations";
import { OrderPickupBoxSchema } from "../../validations/order_pickup_box.validations";
import FormOrderEcommerce from "../FormOrderEcommerce";
import FormOrderPickup from "../FormOrderPickup";
import FormOrderPickupBox from "../FormOrderPickupBox";
import FormOrderSameday from "../FormOrderSameday";
import { inputs } from "./inputs";

const FormManageOrder = (props: any) => {
  const { handleClose } = props;
  const [state, setState] = useState<any>({
    companies: [],
    selectCompanies: [],
    formType: 0,
    checked: false,
    validations: {},
    company: {},
    disableSamedayInputs: true,
    inputs: { ...inputs },
  });
  const dispatch: any = useDispatch();
  const Company = new CompanyService();
  const DeliveryUser = new DeliveryUserService();
  const Order = new OrderService();
  const Box = new BoxService();

  useEffect(() => {
    _loadCompanies();
  }, []);

  const _loadCompanies = async () => {
    dispatch(set_loader({ is_loading: true }));
    try {
      const response: any = await Company.listByUser();
      const selectCompanies: any = response.data?.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setState({ ...state, selectCompanies, companies: response.data });
      dispatch(set_loader({ is_loading: false }));
    } catch (e: any) {
      console.log("LOGGER", e.message);
      _handleError(e, e.message);
      dispatch(set_loader({ is_loading: false }));
      handleClose();
    }
  };

  const _handleSelectCompany = async (value: any) => {
    if (value === null) {
      Object.keys(state.inputs).forEach((key: any) => {
        state.inputs[key] = "";
      });
      setState({
        ...state,
        formType: 0,
        terminalList: [],
        inputs: { ...state.inputs, terminal_id: null },
      });
      return;
    }

    const company = state.companies.find((item: any) => item.id === value);
    if (company) {
      let formType = 0;
      switch (company?.company_type.name) {
        case "residencial":
          formType = 1;
          break;
        case "pickup con reserva":
          formType = 2;
          break;
        case "ecommerce":
          formType = 3;
          break;
        case "sameday":
          formType = 4;
          break;
        case "pickup sin reserva":
          formType = 5;
          break;
        default:
          console.log("[LOGGER]", "opcion invalida");
      }

      const terminals = await Company.getTerminals(company.id);
      const selectlistTerminals: any = terminals.data?.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setState({
        ...state,
        formType,
        terminals: terminals.data,
        terminalList: selectlistTerminals,
        company,
        inputs: { ...inputs },
        validations: {},
      });
    }
  };

  const _handleSelectTerminal = async (value: any) => {
    try {
      dispatch(loading_on());
      console.log(state.companies);
      const boxes: any = await Box.availableBoxes(value);
      setState({
        ...state,
        inputs: { ...inputs, terminal_id: value },
        boxes: boxes.data,
        validations: {},
      });
      dispatch(loading_off());
    } catch (e: any) {
      console.log("LOGGER", e.message);
      dispatch(set_loader({ is_loading: false }));
      _handleError(e, e.message);
    }
  };

  const _handleOnChangeInputs = async ({ target }: any) => {
    const { name, value } = target;
    state.inputs[name] = value;
    if (state.company?.company_type?.name === "sameday" && name === "start") {
      try {
        dispatch(loading_on());
        const start_date = moment(value);
        const boxes: any = await Box.availableSamedayBoxes(
          state.inputs?.terminal_id,
          start_date.format("yyyy-MM-DD")
        );

        if (boxes.data?.length === 0) {
          dispatch(loading_off());
          _pushToastMessage({
            type: "error",
            header: "Error",
            text: "No hay cajas disponibles para este dia.",
          });
          return;
        }
        setState({ ...state, disableSamedayInputs: false, boxes: boxes.data });
        dispatch(loading_off());
      } catch (e: any) {
        _handleError(e, "No fue posible cargar las cajas disponibles");
        dispatch(loading_off());
      }
    } else {
      setState({ ...state });
    }
  };

  const _handleSearchDeliveryUserByEmail = async () => {
    if (!state.checked) return;

    const { inputs } = state;
    const { email } = inputs;
    dispatch(set_loader({ is_loading: true }));
    try {
      const delivery_user: any = await DeliveryUser.getDeliveryUserByEmail(
        email
      );
      const { data } = delivery_user;
      state.inputs.name = data.name;
      state.inputs.rut = data.rut;
      state.inputs.phone = data.phone;
      state.inputs.email = data.email;
      setState({ ...state });
      dispatch(set_loader({ is_loading: false }));
    } catch (e: any) {
      console.log("[LOGGER]", e.message);
      dispatch(set_loader({ is_loading: false }));
    }
  };

  const _handleSubmit = async () => {
    switch (state.formType) {
      case 2:
        _handleSubmitPickup();
        return;
      case 3:
        _handleSubmitEcommerce();
        return;
      case 4:
        _handleSubmitSameday();
        return;
      case 5:
        _handleSubmitPickupBox();
        return;
    }

    _pushToastMessage({
      text: "La empresa seleccionada no encaja en ninguno de los modelos actuales. Póngase en contacto con un administrador del sistema para revisar la configuración de esta empresa.",
      type: "warning",
      header: "Aviso",
    });
  };

  const _handleSubmitPickup = async () => {
    const { inputs, company } = state;
    const payload: any = { ...inputs, company_id: company.id };

    const validations: any = await validate(OrderPickupSchema, state.inputs);
    setState({ ...state, validations });
    if (validations) return;

    if (state.inputs.boxes?.length === 0) {
      _handleError({}, "Elija al menos una caja antes de continuar");
      return;
    }

    dispatch(set_loader({ is_loading: true }));
    try {
      await Order.createPickupOrder(payload);
      _pushToastMessage({
        type: "success",
        header: "Succeso",
        text: "Orden creada con éxito.",
      });
      handleClose(true);
    } catch (e: any) {
      console.log("LOGGER", e.message);
      _handleError(e, e.message);
      dispatch(set_loader({ is_loading: false }));
    }
  };

  const _handleSubmitPickupBox = async () => {
    const { inputs, company } = state;
    const payload: any = { ...inputs, company_id: company.id };

    const validations: any = await validate(OrderPickupBoxSchema, state.inputs);
    setState({ ...state, validations });
    if (validations) return;

    dispatch(set_loader({ is_loading: true }));
    try {
      await Order.createPickupBoxOrder(payload);
      _pushToastMessage({
        type: "success",
        header: "Succeso",
        text: "Orden creada con éxito.",
      });
      handleClose(true);
    } catch (e: any) {
      console.log("LOGGER", e.message);
      _handleError(e, e.message);
      dispatch(set_loader({ is_loading: false }));
    }
  };

  const _handleSubmitEcommerce = async () => {
    const { inputs, company } = state;
    const start: any = moment(inputs.start).format("YYYY-MM-DD hh:mm:ss");
    const payload: any = {
      ...inputs,
      company_id: company.id,
      start,
    };

    const validations: any = await validate(OrderEcommerceSchema, state.inputs);
    setState({ ...state, validations });
    if (validations) return;

    dispatch(set_loader({ is_loading: true }));
    try {
      await Order.createEcommerceOrder(payload);
      _pushToastMessage({
        type: "success",
        header: "Succeso",
        text: "Orden creada con éxito.",
      });
      handleClose(true);
    } catch (e: any) {
      console.log("LOGGER", e.message);
      _handleError(e, e.message);
      dispatch(set_loader({ is_loading: false }));
    }
  };
  const _handleSubmitSameday = async () => {
    const { inputs, company } = state;
    const start: any = moment(inputs.start).format("YYYY-MM-DD");
    const payload: any = { ...inputs, company_id: company.id, start };

    const validations: any = await validate(OrderEcommerceSchema, state.inputs);
    setState({ ...state, validations });
    if (validations) return;

    dispatch(set_loader({ is_loading: true }));
    console.log(payload)
    try {
      await Order.createSamedayOrder(payload);
      _pushToastMessage({
        type: "success",
        header: "Succeso",
        text: "Orden creada con éxito.",
      });
      handleClose(true);
    } catch (e: any) {
      console.log("LOGGER", e.message);
      _handleError(e, e.message);
      dispatch(set_loader({ is_loading: false }));
    }
  };

  return (
    <div className="container-fluid">
      <div className="row mb-3">
        <div className="col-12">
          <span className="size-08 bold-300">Empresa</span>
          <SelectPicker
            className="w-100"
            onChange={_handleSelectCompany}
            locale={{
              emptyMessage: "No fue encontrado ninguna empresa",
              placeholder: "Seleccione una empresa",
              noResultsText: "No fue encontrado ninguna empresa",
              searchPlaceholder: "Buscar",
            }}
            data={state.selectCompanies}
          />
        </div>
      </div>

      {state.formType > 1 && (
        <div className="row mb-3">
          <div className="col-12 mb-3">
            <span className="size-08 bold-300">Terminal</span>
            <SelectPicker
              className="w-100"
              onChange={_handleSelectTerminal}
              locale={{
                emptyMessage: "No fue encontrado ningun terminal",
                placeholder: "Seleccione un terminal",
                noResultsText: "No fue encontrado ningun terminal",
                searchPlaceholder: "Buscar",
              }}
              data={state.terminalList || []}
              value={state.inputs?.terminal_id}
            />
            <ErrorValidation
              name="terminal_id"
              className="size-08 text-end"
              validations={state.validations}
            />
          </div>
        </div>
      )}
      {state.formType === 1 && (
        <div className="row mt-3 px-3">
          <div className="col-12 size-09 alert alert-warning">
            Esta empresa pertenece al modelo residencial y no admite la creación
            de ordenes que no sea a través de un terminal.
          </div>
        </div>
      )}

      {state.formType === 2 && state.inputs?.terminal_id && (
        <>
          <div className="col-12">
            <div className="size-08 bold-300">
              Verificar se email existe en la base de dados
            </div>
            <Toggle
              checked={state.checked}
              onChange={(checked: any) =>
                setState({ ...state, checked: checked })
              }
            />
          </div>
          <FormOrderPickup
            inputs={state.inputs}
            setInputs={_handleOnChangeInputs}
            handleOnBlur={_handleSearchDeliveryUserByEmail}
            validations={state.validations}
            handleClose={handleClose}
            handleConfirm={_handleSubmit}
            boxes={state.boxes}
          />
        </>
      )}

      {state.formType === 3 && state.inputs?.terminal_id && (
        <>
          <FormOrderEcommerce
            inputs={state.inputs}
            setInputs={_handleOnChangeInputs}
            handleOnBlur={_handleSearchDeliveryUserByEmail}
            validations={state.validations}
            handleClose={handleClose}
            handleConfirm={_handleSubmit}
            boxes={state.boxes}
          />
        </>
      )}

      {state.formType === 4 && state.inputs?.terminal_id && (
        <>
          <FormOrderSameday
            inputs={state.inputs}
            setInputs={_handleOnChangeInputs}
            handleOnBlur={_handleSearchDeliveryUserByEmail}
            validations={state.validations}
            handleClose={handleClose}
            handleConfirm={_handleSubmit}
            boxes={state.boxes}
            disabled={state.disableSamedayInputs}
          />
        </>
      )}

      {state.formType === 5 && state.inputs?.terminal_id && (
        <>
          <div className="col-12">
            <div className="size-08 bold-300">
              Verificar se email existe en la base de dados
            </div>
            <Toggle
              checked={state.checked}
              onChange={(checked: any) =>
                setState({ ...state, checked: checked })
              }
            />
          </div>
          <FormOrderPickupBox
            inputs={state.inputs}
            setInputs={_handleOnChangeInputs}
            handleOnBlur={_handleSearchDeliveryUserByEmail}
            validations={state.validations}
            handleClose={handleClose}
            handleConfirm={_handleSubmit}
            boxes={state.boxes}
          />
        </>
      )}
    </div>
  );
};

export default FormManageOrder;
