import api from "./api";
import store from "../redux/store";

class FeatureService {
  constructor() {
    const { session } = store.getState();
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${session.security_token}`;
  }

  list = async (search: any = null) => {
    try {
      let query: any = "";
      if (search) {
        query =
          "?" +
          Object.keys(search)
            .map((key: any) => `${key}=${search[key]}`)
            .join("&");
      }
      const response = await api.get(`/feature${query}`);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  find = async (param:any) => {
    try {
      const response = await api.get(`/feature/find/${param}`);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

export default FeatureService;
