import api from "./api";
import store from "../redux/store";

class CompanyTypeService {
  constructor() {
    const { session } = store.getState();
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${session.security_token}`;
  }

  list = async (search: any = null) => {
    try {
      let query: any = "";
      if (search) {
        query = `?name=${search}&email=${search}`;
      }
      const response = await api.get(`/company-types${query}`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

export default CompanyTypeService;
