export default interface IBox {
  name: string;
  width: string;
  height: string;
  depht: string;
}

export const attributes = {
  id: "Numero Orden",
  delivery_user_id: "Nombre",
  delivery_user_address: "Dirección",
  delivery_user_number: "Departamento",
  terminal: "Terminal",
  company: "Empresa",
  code: "Código",
  state: "Estado",
  external_reference: 'Referencia Externa',
  // delivered_at: 'Fecha Delivery',
  // withdrawn_at: 'Fecha Retiro',
};
