import moment from "moment";
import * as yup from "yup";

export const OrderEcommerceSchema = yup.object().shape({
  name: yup.string().nullable().required("Este campo es requerido"),
  email: yup
    .string()
    .email("Formato de email invalido")
    .nullable()
    .required("Este campo es requerido"),
  rut: yup.string().nullable().required("Este campo es requerido"),
  phone: yup.string().nullable().required("Este campo es requerido"),
  terminal_id: yup.string().nullable().required("Este campo es requerido"),
  start: yup
    .date()
    .min(moment().hour(0).minute(0).toDate(), "Fecha inválida")
    .required("Este campo es requerido"),
  boxes: yup.array().min(1, "Este campo es requerido"),
});

export type OrderEcommerceType = yup.InferType<typeof OrderEcommerceSchema>;
