/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Input, Modal } from "rsuite";
import ErrorValidation from "../../components/ErrorValidation";
import { _pushToastMessage } from "../../helpers";
import { _handleError } from "../../helpers/errors";
import validate from "../../helpers/validate";
import { loading_off, loading_on } from "../../redux/actions/loader";
import BoxTypeService from "../../services/box_type.service";
import { BoxTypeSchema } from "../../validations/box.type.validations";

const FormLog = (props: any) => {
  const { type, data, handleClose, getData } = props;
  const [state, setState] = useState<any>({
    name: "",
    width: "",
    height: "",
    depht: "",
    validations: {},
  });

  const BoxType = new BoxTypeService();
  const dispatch: any = useDispatch();

  useEffect(() => {
    if (type !== "create") {
      setState({ ...state, ...data });
    }
  }, []);

  const _handleOnChange = ({ target }: any) => {
    const { name, value } = target;
    state[name] = value;
    setState({ ...state });
  };

  const _handleOnSubmit = async (e: any) => {
    e.preventDefault();
    if (type === "view") {
      handleClose();
      return;
    }

    const validations: any = await validate(BoxTypeSchema, state);

    setState({ ...state, validations });

    if (validations) {
      return;
    }

    dispatch(loading_on());

    const payload: any = {
      name: state.name,
      width: state.width,
      height: state.height,
      depht: state.depht,
    };

    try {
      if (type === "create") {
        await BoxType.create(payload);
      }

      if (type === "update") {
        payload.id = state.id;
        await BoxType.update(payload);
      }

      _pushToastMessage({
        type: "success",
        header: "Succeso",
        text: `Caja ${type === "create" ? "creada" : "actualizada"
          } con éxito...`,
      });

      getData();
    } catch (e: any) {
      dispatch(loading_off());
      _handleError(e, e.message);
    }
  };

  return (
    <div className="container-fluid">
      <form onSubmit={_handleOnSubmit}>
        <div className="row">
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Level</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.level}</div>
            ) : (
              <Input
                placeholder="Level"
                value={state.level}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "level", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="level"
              validations={state.validations}
            />
          </div>
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Dominio</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2">{state.domain}</div>
            ) : (
              <Input
                placeholder="Dominio"
                value={state.domain}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "domain", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="domain"
              validations={state.validations}
            />
          </div>
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Acción</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.action}</div>
            ) : (
              <Input
                placeholder="Acción"
                value={state.action}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "action", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="action"
              validations={state.validations}
            />
          </div>
          <div className="col-12 mb-2">
            <label className="size-08 bold-300 ms-1">Descripción</label>
            {type && type === "view" ? (
              <div
                className="size-08 ms-2">
                {state.description}
              </div>
            ) : (
              <Input
                placeholder="Descripción"
                value={state.description}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "description", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="description"
              validations={state.validations}
            />
          </div>
        </div>
        <div className="row mt-4">
          <Modal.Footer>
            <Button onClick={handleClose} appearance="subtle">
              Cerrar
            </Button>
            <Button type="submit" appearance="primary">
              Confirmar
            </Button>
          </Modal.Footer>
        </div>
      </form>
    </div>
  );
};

export default FormLog;
