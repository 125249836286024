/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Input } from "rsuite";
import { _pushToastMessage } from "../../helpers";
import Logo from "../../components/Logo";
import Auth from "../../services/auth.service";
import "./style.scss";
import { useDispatch } from "react-redux";
import { set_loader } from "../../redux/actions/loader";
import { set_session } from "../../redux/actions/session";
import { useNavigate } from "react-router-dom";
import { _handleError } from "../../helpers/errors";
import validate from "../../helpers/validate";
import { SignInSchema } from "../../validations/signin.validations";
import ErrorValidation from "../../components/ErrorValidation";

const SignIn = () => {
  const [state, setState] = useState({
    email: "",
    password: "",
    validations: {},
  });

  const dispatch: any = useDispatch();
  const { session } = useSelector((state: any) => state);
  const navigate: any = useNavigate();

  useEffect(() => {
    if (session.is_logged) {
      if(session.profile?.roles?.find((role:any) => role.name === 'user')) {
        navigate('/orders')
        return;
      };
      if(session.profile?.roles?.find((role:any) => role.name === 'api')) {
        navigate('/documentation')
        return
      };
      navigate("/");
    }
  }, [session]);

  const _handleOnChange = ({ target }: any) => {
    const { name, value } = target;
    setState({ ...state, [name]: value });
  };

  const _handleSubmit = async (event: any) => {
    event.preventDefault();
    const validations: any = await validate(SignInSchema, state);
    if (validations !== false) {
      setState({ ...state, validations });
      return;
    }

    setState({ ...state, validations: {} });

    dispatch(set_loader({ is_loading: true }));

    try {
      const response = await Auth.signin(state);

      dispatch(set_session({ ...response.data, is_logged: true }));
      dispatch(set_loader({ is_loading: false }));
      _pushToastMessage({
        type: "success",
        header: "Succeso",
        text: "Sesion iniciada con éxito",
      });
    } catch (e: any) {
      dispatch(set_loader({ is_loading: false }));
      _handleError(e, "No fue posible iniciar sesion");
    }
  };

  return (
    <div className="container-fluid sign-in p-0">
      <div className="content d-flex justify-content-center align-items-center p-3">
        <div className="sign-in-box shadow">
          <form>
            <div className="row">
              <div className="col-12 text-center mb-4">
                <Logo style={{ width: "100px" }} />
              </div>
              <div className="col-12 mb-3">
                Email
                <Input
                  value={state.email}
                  onChange={(e: any) =>
                    _handleOnChange({ target: { name: "email", value: e } })
                  }
                />
                <div className="text-end">
                  <ErrorValidation
                    validations={state.validations}
                    name="email"
                  />
                </div>
              </div>
              <div className="col-12 mb-3">
                Contraseña
                <Input
                  type="password"
                  value={state.password}
                  onChange={(e: any) =>
                    _handleOnChange({ target: { name: "password", value: e } })
                  }
                />
                <div className="text-end">
                  <ErrorValidation
                    validations={state.validations}
                    name="password"
                  />
                </div>
              </div>
              <div className="col-12 text-center">
                <Button
                  type="submit"
                  appearance="primary"
                  onClick={_handleSubmit}
                >
                  {" "}
                  Entrar{" "}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
