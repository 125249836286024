import { useEffect, useRef, useState } from "react";
import { ImSearch } from "react-icons/im";
import { Button, Input, Modal } from "rsuite";
import ButtonIcon from "../ButtonIcon";

const Search = (props: any) => {
  const { handleClose, handleSearch, placeholder } = props;
  const inputRef: any = useRef(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const _handleSearch = (e: any) => {
    e.preventDefault();
    handleSearch(search);
  };
  return (
    <div className="row w-100">
      <form onSubmit={_handleSearch}>
        <div className="col-12 position relative">
          <Input
            placeholder={placeholder}
            className="py-4"
            value={search}
            onChange={(value: any) => setSearch(value)}
            ref={inputRef}
          />
          <div
            className="position-absolute"
            style={{ top: "15px", right: "20px" }}
          >
            <ButtonIcon Icon={ImSearch} style={{ color: "#aeaeae" }} />
          </div>
        </div>

        <div className="col-12 mt-4">
          <Modal.Footer>
            <Button onClick={handleClose} appearance="subtle">
              Cerrar
            </Button>
            <Button type="submit" appearance="primary">
              Buscar
            </Button>
          </Modal.Footer>
        </div>
      </form>
    </div>
  );
};

export default Search;
