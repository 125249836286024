import * as yup from "yup";

export const OrderPickupSchema = yup.object().shape({
  name: yup.string().nullable().required("Este campo es requerido"),
  email: yup
    .string()
    .email("Formato de email invalido")
    .nullable()
    .required("Este campo es requerido"),
  rut: yup.string().nullable().required("Este campo es requerido"),
  phone: yup.string().nullable().required("Este campo es requerido"),
  terminal_id: yup.string().nullable().required("Este campo es requerido"),
});

export type OrderPickupType = yup.InferType<typeof OrderPickupSchema>;
