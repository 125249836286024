/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, TagPicker } from "rsuite";
import { _pushToastMessage } from "../../helpers";
import { _handleError } from "../../helpers/errors";
import { set_loader } from "../../redux/actions/loader";
import CompanyService from "../../services/company.service";
import TerminalService from "../../services/terminal.service";

const FormCompanyTerminal = (props: any) => {
  const { handleClose, data } = props;
  const [state, setState] = useState<any>({
    terminals: [],
    selectedTerminals: [],
  });
  const dispatch: any = useDispatch();
  const Terminal = new TerminalService();
  const Company = new CompanyService();

  useEffect(() => {
    _loadTerminals();
  }, []);

  const _loadTerminals = async () => {
    dispatch(set_loader({ is_loading: true }));
    try {
      const response: any = await Terminal.selectList();
      setState({
        ...state,
        terminals: response.data,
        selectedTerminals:
          data?.terminals?.map((terminal: any) => terminal.id) || [],
      });
      dispatch(set_loader({ is_loading: false }));
    } catch (e: any) {
      console.log("LOGGER", e.message);
      handleClose();
    }
  };

  const _handleChange = ({ target }: any) => {
    const { name, value } = target;
    state[name] = value;
    setState({ ...state });
  };

  const _handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await Company.asociateTerminals({
        list: state.selectedTerminals,
        company_id: data?.id,
      });

      _pushToastMessage({
        type: "success",
        header: "Éxito",
        text: "Terminales asociados con éxito",
      });
      handleClose();
    } catch (e: any) {
      console.log("LOGGER", e.message);
      _handleError(e, e.message);
    }
  };

  return (
    <div className="container-fluid">
      <form onSubmit={_handleSubmit}>
        <div className="row mb-4">
          <div className="col-6">
            <div className="size-08 bold-300">Nombre empresa</div>
            <div className="size-08 ms-2">{data?.name}</div>
          </div>
          <div className="col-6">
            <div className="size-08 bold-300">Dirección</div>
            <div className="size-08 ms-2">{data?.address}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="size-08 bold-300">Terminales</div>
            <TagPicker
              className="w-100"
              data={state.terminals}
              value={state.selectedTerminals}
              onChange={(e: any) =>
                _handleChange({
                  target: { name: "selectedTerminals", value: e },
                })
              }
              locale={{
                emptyMessage: "No fue encontrado ningun terminal",
                placeholder: "Seleccione uno o más terminales",
                noResultsText: "No fue encontrado ningun terminal",
                searchPlaceholder: "Buscar",
              }}
            />
          </div>
        </div>
        <div className="row mt-4">
          <Modal.Footer>
            <Button onClick={handleClose} appearance="subtle">
              Cerrar
            </Button>
            <Button type="submit" appearance="primary">
              Confirmar
            </Button>
          </Modal.Footer>
        </div>
      </form>
    </div>
  );
};

export default FormCompanyTerminal;
