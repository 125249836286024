import * as yup from "yup";

export const BoxSchema = yup.object().shape({
  model_type: yup.string().nullable().required("Este campo es requerido"),
  hexadecimal: yup.string().nullable().when('model_type', {
    is: (model_type:any) => {
      return parseInt(model_type) === 2
    },
    then: yup.string().required("Este campo es requerido")
  }),
  desk_door_number: yup.string().nullable().required("Este campo es requerido"),
  desk_number: yup.string().nullable().required("Este campo es requerido"),
  door_number: yup.string().nullable().required("Este campo es requerido"),
  box_type_id: yup.number().nullable().required("Este campo es requerido"),
});

export type BoxType = yup.InferType<typeof BoxSchema>;
