import * as yup from "yup";

export const BoxTypeSchema = yup.object().shape({
  name: yup.string().nullable().required("Este campo es requerido"),
  width: yup.string().nullable().required("Este campo es requerido"),
  height: yup.string().nullable().required("Este campo es requerido"),
  depht: yup.string().nullable().required("Este campo es requerido"),
});

export type BoxTypeType = yup.InferType<typeof BoxTypeSchema>;
