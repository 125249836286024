export default interface IBox {
  name: string;
  width: string;
  height: string;
  depht: string;
}

export const attributes = {
  level: "Tipo",
  domain: "Dominio",
  action: "Acción",
  description: "Descripción",
};
