/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ModalComponent from "../../components/Modal/Modal";
import Search from "../../components/Search";
import FormBox from "../../forms/FormBox";
import { _pushToastMessage } from "../../helpers";
import { loading_off, loading_on } from "../../redux/actions/loader";
import DefaultTemplate from "../../templates/DefaultTemplate";
import Header from "./Header";
import BoxTypeService from "../../services/box_type.service";

import { TableComponent } from "./TableComponent";
import { _handleError } from "../../helpers/errors";
import ConfirmationModal from "../../components/ConfirmationModal";
import PaginateComponent from "../../components/Paginate";

const Boxes = () => {
  const [data, setData] = useState<any>([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState<any>([]);
  const [state, setState] = useState<any>({});
  const dispatch: any = useDispatch();
  const BoxType = new BoxTypeService();

  const [confirmation, setConfirmation] = useState<any>({
    open: false,
    text: "",
    handleClose: () => {},
    handleConfirm: () => {},
  });

  useEffect(() => {
    setChecked(false);
    setIndeterminate(false);
    if (checkedKeys.length === data?.data?.length) {
      setChecked(true);
    } else if (checkedKeys.length === 0) {
      setChecked(false);
    } else if (
      checkedKeys.length > 0 &&
      checkedKeys.length < data?.data?.length
    ) {
      setIndeterminate(true);
    }
  }, [checkedKeys, data]);

  useEffect(() => {
    _getData();
  }, []);

  const _getData = async (search: any = null) => {
    dispatch(loading_on());

    try {
      let response = null;
      if (search !== null) {
        response = await BoxType.list({ name: search });
      } else {
        response = await BoxType.list();
      }

      setData(response.data);

      setState({
        ...state,
        createBoxModal: false,
        searchModal: false,
      });

      dispatch(loading_off());
    } catch (e: any) {
      dispatch(loading_off());
      _handleError(e, e.message);
    }
  };

  const _handleSelectChangeGlobal = (payload: any) => {
    switch (payload.option) {
      case 1:
        console.log(payload);
        break;
      case 2:
        setConfirmation({
          open: true,
          text: "¿Seguro que quieres eliminar las cajas seleccionadas?",
          handleClose: () => setConfirmation({ ...confirmation, open: false }),
          handleConfirm: () => _deleteSelected(checkedKeys),
        });
        break;
      default:
        console.log(payload);
    }
  };

  const _deleteSelected = async (list: any) => {
    dispatch(loading_on());

    try {
      await BoxType.deleteList(list);
      _pushToastMessage({
        type: "success",
        header: "Éxito",
        text: "Cajas eliminadas con éxito",
      });
      setConfirmation({ ...confirmation, open: false });
      _getData();
    } catch (e: any) {
      _handleError(e, e.message);
      setConfirmation({ ...confirmation, open: false });
      dispatch(loading_off());
    }
  };

  const _handleCheckAll = (value: any, checked: any) => {
    const keys: any = checked ? data?.data?.map((item: any) => item.id) : [];
    setCheckedKeys([...keys]);
  };

  const _handleCheck = (value: any, checked: any) => {
    const keys: any = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter((item: any) => item !== value);
    setCheckedKeys([...keys]);
  };

  const _toggleCreateBoxModal = () =>
    setState({ ...state, createBoxModal: !state.createBoxModal });

  const _toggleSearchModal = () =>
    setState({ ...state, searchModal: !state.searchModal });

  return (
    <DefaultTemplate>
      <ModalComponent
        open={state.createBoxModal}
        size="md"
        title="Agregar Caja"
        handleClose={_toggleCreateBoxModal}
      >
        <FormBox type="create" getData={_getData} />
      </ModalComponent>

      <ModalComponent
        open={state.searchModal}
        size="md"
        title="Busca rapida"
        handleClose={_toggleSearchModal}
      >
        <Search
          handleSearch={(value: any) => _getData(value)}
          placeholder="Busca por el nombre de una caja"
        />
      </ModalComponent>

      <ModalComponent
        open={confirmation.open}
        size="md"
        title="Eliminar empresa"
        handleClose={() => confirmation.handleClose()}
      >
        <ConfirmationModal
          text={confirmation.text}
          handleConfirm={confirmation.handleConfirm}
        />
      </ModalComponent>

      <div className="row mx-0 px-4 py-5 with-header">
        <div className="default-template-box">
          <div className="row">
            <div className="col-12 justify-content-between align-items-center d-flex py-2 px-3 bold-300 line-border">
              <Header
                handleSelectChange={_handleSelectChangeGlobal}
                handleSearchModal={_toggleSearchModal}
                handleCreateModal={_toggleCreateBoxModal}
              />
            </div>
            <div className="col-12 py-5">
              <TableComponent
                indeterminate={indeterminate}
                checked={checked}
                data={data}
                getData={_getData}
                checkedKeys={checkedKeys}
                handleCheckAll={_handleCheckAll}
                handleCheck={_handleCheck}
              />
            </div>
            <div className="col-12 text-center mb-5">
              <PaginateComponent data={data} setData={setData} />
            </div>
          </div>
        </div>
      </div>
    </DefaultTemplate>
  );
};

export default Boxes;
