import api from "./api";
import store from "../redux/store";

class TerminalService {
  constructor() {
    const { session } = store.getState();
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${session.security_token}`;
  }

  list = async (search: any = null) => {
    try {
      let query: any = "";
      if (search) {
        query =
          "?" +
          Object.keys(search)
            .map((key: any) => `${key}=${search[key]}`)
            .join("&");
      }
      const response = await api.get(`/terminals${query}`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  selectList = async () => {
    try {
      const response = await api.get(`/terminals/list/select-list`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  create = async (payload: any) => {
    try {
      const response = await api.post("/terminals", payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  update = async (payload: any) => {
    try {
      const response = await api.put(`/terminals/update/${payload.id}`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  delete = async (id: any) => {
    try {
      const response = await api.delete(`/terminals/${id}`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  deleteList = async (list: any) => {
    try {
      const response: any = await api.post(`/terminals/delete-list`, {
        list: list,
      });
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
  
}

export default TerminalService;
