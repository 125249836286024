import { useEffect, useState } from "react";
import { Button, Input, MaskedInput, Modal, TagPicker } from "rsuite";
import ErrorValidation from "../../components/ErrorValidation";
import { phoneMask } from "../../helpers/masks";

const FormOrderPickup = (props: any) => {
  const {
    inputs,
    setInputs,
    handleOnBlur,
    validations,
    handleClose,
    handleConfirm,
    boxes,
  } = props;

  const [listBoxes, setListBoxes] = useState<any>([]);

  useEffect(() => {
    const listBoxes: any = boxes?.map((item: any) => {
      return {
        label: `${item?.box_type?.name} - Caja ${item?.door_number}`,
        value: item.id,
      };
    });

    setListBoxes([...listBoxes]);
  }, [boxes]);

  return (
    <div className="p-0 m-0">
      <div className="row mb-3">
        <div className="col-6">
          <div className="size-08 bold-300">Email</div>
          <div className="w-100">
            <Input
              name="email"
              value={inputs.email}
              onChange={(e: any) =>
                setInputs({ target: { name: "email", value: e } })
              }
              onBlur={handleOnBlur}
            />
            <ErrorValidation
              className="size-08 text-end"
              name="email"
              validations={validations}
            />
          </div>
        </div>

        <div className="col-6">
          <div className="size-08 bold-300">Nombre</div>
          <div className="w-100">
            <Input
              name="name"
              value={inputs.name}
              onChange={(e: any) =>
                setInputs({ target: { name: "name", value: e } })
              }
            />
            <ErrorValidation
              className="size-08 text-end"
              name="name"
              validations={validations}
            />
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-6">
          <div className="size-08 bold-300">Rut</div>
          <div className="w-100">
            <Input
              name="rut"
              value={inputs.rut}
              onChange={(e: any) =>
                setInputs({ target: { name: "rut", value: e } })
              }
            />
            <ErrorValidation
              className="size-08 text-end"
              name="rut"
              validations={validations}
            />
          </div>
        </div>

        <div className="col-6">
          <div className="size-08 bold-300">Telefono</div>
          <div className="w-100">
            <MaskedInput
              mask={phoneMask}
              value={inputs.phone}
              guide={true}
              showMask={true}
              keepCharPositions={true}
              placeholder={""}
              placeholderChar={"_"}
              onChange={(e: any) =>
                setInputs({ target: { name: "phone", value: e } })
              }
            />
            <ErrorValidation
              className="size-08 text-end"
              name="phone"
              validations={validations}
            />
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-6">
          <div className="size-08 bold-300">Nombre del item</div>
          <div className="w-100">
            <Input
              name="package_name"
              value={inputs.package_name}
              onChange={(e: any) =>
                setInputs({ target: { name: "package_name", value: e } })
              }
            />
          </div>
        </div>

        <div className="col-6">
          <div className="size-08 bold-300">Numero orden</div>
          <div className="w-100">
            <Input
              name="external_reference"
              value={inputs.external_reference}
              onChange={(e: any) =>
                setInputs({ target: { name: "external_reference", value: e } })
              }
            />
          </div>
        </div>
      </div>

      <div className="row mb-5">
        <div className="size-09 bold-300 mb-3">Cajas disponibles</div>
        {boxes && boxes.length > 0 && (
          <div className="col-12">
            <TagPicker
              className="w-100"
              data={listBoxes}
              locale={{
                emptyMessage: "No hay cajas disponibles para este terminal",
                noResultsText: "no hay cajas disponibles para este terminal",
                placeholder: "Selecciona una o mas cajas",
              }}
              onChange={(e: any) =>
                setInputs({ target: { name: "boxes", value: e } })
              }
            />
          </div>
        )}
      </div>

      <div className="row">
        <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            Cerrar
          </Button>
          <Button type="submit" appearance="primary" onClick={handleConfirm}>
            Confirmar
          </Button>
        </Modal.Footer>
      </div>
    </div>
  );
};

export default FormOrderPickup;
