export default interface IUser {
  username: string,
  address: string,
  door_com_number: string,
  qrcode_com_number: string,
  company: string,
  module: string,
}

export const attributes = {
  name: "Nombre Terminal",
  username: "Nombre Usuario",
  address: "Dirección",
  door_com_number: "COM Puerta",
  qrcode_com_number: "COM Qrcode",
  company: "Empresa",
  module: "Modulo",
};
