import axios from "axios";
import { clear_session } from "../redux/actions/session";
import store from "../redux/store";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ADMIN,
  headers: {
    "Content-type": "application/json",
  },
});

instance.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: any) => {
    if (error.response && error.response.status === 401) {
      const dispatch: any = store.dispatch;
      dispatch(clear_session());
    }
    return Promise.reject(error);
  }
);

export default instance;
