export default interface IBox {
  name: string;
  width: string;
  height: string;
  depht: string;
}

export const attributes = {
  logo: "Logo",
  name: "Nombre",
};
