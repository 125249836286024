import DefaultTemplate from "../../templates/DefaultTemplate";

const PostmanDoc = () => {
    return (
        <DefaultTemplate>
            <div className="row mx-0 px-4 py-5 with-header">
                <div className="default-template-box mb-3 p-4 size-09">
                    <div className="col-12 mb-4">
                        <h5>Postman</h5>
                    </div>
                    <div className="col-12">
                        Conjunto de endpoints configurados para acceso a la api externa de elock.
                    </div>
                    <div className="col-12 mb-4">
                        <a href="/documentation/postman_collection.json" rel="noreferrer" download>Postman Collection</a>
                    </div>
                    <div className="col-12">
                        Archivo de ambiente con las urls de configuraciones.
                    </div>
                    <div className="col-12 mb-4">
                        <a href="/documentation/postman_enviroment.json" rel="noreferrer" download>Postman Enviroment</a>
                    </div>

                </div>
            </div>
        </DefaultTemplate>
    );
}

export default PostmanDoc;