import { TiFilter, TiThSmall, TiEye, TiPlus } from "react-icons/ti";
import { RiUpload2Line } from "react-icons/ri";
import { FaEyeSlash } from "react-icons/fa";
import { ImSearch } from "react-icons/im";
import { Whisper } from "rsuite";
import ButtonIcon from "../../../components/ButtonIcon";
import { MoreMenu } from "../../../components/MoreMenu";
import { _actionMenuSpeakerAll } from "../speaker";
const Header = (props: any) => {
  const {
    showCode,
    setShowCode,
    handleSearchModal,
    handleOrderModal,
    handleFilters,
    handleMassiveLoad,
    filters
  } = props;

  const Actions = ({ props }: any) => {
    const { handleSelectChange } = props;
    const _speaker: any = _actionMenuSpeakerAll(handleSelectChange);

    return (
      <Whisper
        placement="auto"
        trigger="click"
        speaker={(whisper_payload: any, ref: any) =>
          MoreMenu(whisper_payload, ref, _speaker)
        }
      >
        <button className="btn">
          <TiThSmall
            style={{ marginTop: "5px", width: "25px", height: "25px" }}
          />
        </button>
      </Whisper>
    );
  };

  return (
    <>
      <div>Ordenes</div>
      <div className="d-flex">
        <ButtonIcon
          Icon={ImSearch}
          label=""
          width="20px"
          height="20px"
          style={{ marginTop: "5px" }}
          onClick={handleSearchModal}
          tooltip="Busca"
        />

        <ButtonIcon
          Icon={TiPlus}
          label=""
          width="25px"
          height="25px"
          style={{ marginTop: "5px" }}
          onClick={handleOrderModal}
          tooltip="Agregar"
        />

        <ButtonIcon
          Icon={TiFilter}
          label=""
          width="25px"
          height="25px"
          style={{ marginTop: "5px", borderBottom: `${filters ? '4px solid #2f89f5' : ''}` }}
          onClick={handleFilters}
          tooltip="Filtros"
        />

        <ButtonIcon
          Icon={RiUpload2Line}
          label=""
          width="25px"
          height="25px"
          style={{ marginTop: "5px" }}
          onClick={handleMassiveLoad}
          tooltip="Carga Masiva"
        />

        {!showCode && (
          <ButtonIcon
            Icon={TiEye}
            label=""
            width="25px"
            height="25px"
            style={{ marginTop: "5px" }}
            onClick={() => setShowCode(!showCode)}
            tooltip="Mostrar Códigos"
          />
        )}

        {showCode && (
          <ButtonIcon
            Icon={FaEyeSlash}
            label=""
            width="25px"
            height="25px"
            style={{ marginTop: "5px" }}
            onClick={() => setShowCode(!showCode)}
            tooltip="Esconder Códigos"
          />
        )}

        <Actions props={props} />
      </div>
    </>
  );
};

export default Header;
