import { Provider } from "react-redux";
import { CustomProvider } from "rsuite";
import store from "./redux/store";
import RouterWrapper from "./routers/RouterWrapper";
import "./styles/_global.scss";
import es_ES from "rsuite/locales/es_ES";

const App = () => {
  return (
    <Provider store={store}>
      <CustomProvider locale={es_ES}>
        <RouterWrapper />
      </CustomProvider>
    </Provider>
  );
};

export default App;
