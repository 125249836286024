import api from "./api";
import store from "../redux/store";

class MessengerService {
  constructor() {
    const { session } = store.getState();
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${session.security_token}`;
  }

  sendMail = async (payload: any) => {
    try {
      const response = await api.post(`/messenger/single/mail`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  sendMailMassive = async (payload: any) => {
    try {
      const response = await api.post(`/messenger/massive/mail`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  sendSms = async (payload: any) => {
    try {
      const response = await api.post(`/messenger/single/sms`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  sendSmsMassive = async (payload: any) => {
    try {
      const response = await api.post(`/messenger/massive/sms`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

export default MessengerService;
