/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clear_session } from "../../redux/actions/session";

const SignOut = () => {
  const dispatch: any = useDispatch();
  const navigate:any = useNavigate();

  useEffect(() => {
    dispatch(clear_session());
    navigate('/sign-in');
  }, []);

  return <></>;
};

export default SignOut;
