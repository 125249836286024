import { Fragment, useEffect, useState } from "react";
import { Checkbox, Divider, Table, Tooltip, Whisper } from "rsuite";
import ParagraphIcon from "@rsuite/icons/Paragraph";
import MoreIcon from "@rsuite/icons/More";
import { MoreMenu } from "../../../components/MoreMenu";
import { _actionMenuSpeaker } from "../speaker";
import { attributes } from "../Schema";
import ModalComponent from "../../../components/Modal/Modal";
import FormOrder from "../../../forms/FormOrder";
import HistoryOrder from "../../../components/HistoryOrder";
import { _pushToastMessage } from "../../../helpers";
import moment from "moment";
import WhisperTooltip from "../../../components/WhisperTooltip";
import { IoWarningOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { reservation_types } from "../reservations_types";
import MessengerService from "../../../services/messenger.service";
import { useDispatch } from "react-redux";
import { loading_off, loading_on } from "../../../redux/actions/loader";

export const TableComponent = (props: any) => {
  const {
    data,
    indeterminate,
    checked,
    handleCheck,
    handleCheckAll,
    checkedKeys,
    showCode,
  } = props;

  const { session } = useSelector((state: any) => state);
  const [tableHeader, setTableHeader] = useState<any>([]);
  const [state, setState] = useState<any>({});
  const dispatch: any = useDispatch();
  const Messenger = new MessengerService();
  const whisperStyle: any = {
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  useEffect(() => {
    const fields: any = { ...attributes };
    const tableHeader: any = Object.keys(fields).map((key: any) => {
      return {
        label: fields[key],
        key: key,
        align: "left",
        alignFlex: "flex-start",
        flexGrow: 1,
      };
    });
    setTableHeader(tableHeader);
  }, []);

  const handleSelectChange = async (payload: any) => {
    let selectedData: any = {};
    switch (payload.option) {
      case 1:
        selectedData = data.data.find((item: any) => item.id === payload.id);
        setState({ ...state, viewHistoryModal: true, selectedData });
        break;
      case 2:
        selectedData = data.data.find((item: any) => item.id === payload.id);
        setState({ ...state, viewModalOrder: true, selectedData });
        break;
      case 3:
        dispatch(loading_on());
        try {
          await Messenger.sendMail(payload);
          _pushToastMessage({
            type: "success",
            header: "Aviso",
            text: "¡Correo reenviado con éxito!",
          });
          dispatch(loading_off());
        } catch (e: any) {
          _pushToastMessage({
            type: "error",
            header: "Aviso",
            text: e?.response?.data?.message || "No fue posible reenviar este correo",
          });
          dispatch(loading_off());
        }
        break;
      case 4:
        dispatch(loading_on());
        try {
          await Messenger.sendSms(payload);
          _pushToastMessage({
            type: "success",
            header: "Aviso",
            text: "SMS reenviado con éxito!",
          });
          dispatch(loading_off());
        } catch (e: any) {
          _pushToastMessage({
            type: "error",
            header: "Aviso",
            text: e?.response?.data?.message || "No fue posible reenviar este sms",
          });
          dispatch(loading_off());
        }
        break;
      default:
        console.log(payload);
    }
  };

  const _toggleViewOrderModal = () =>
    setState({ ...state, viewModalOrder: !state.viewModalOrder });

  const _toggleViewHistoryModal = () =>
    setState({ ...state, viewHistoryModal: !state.viewHistoryModal });

  const _toggleCodeImageModal = () =>
    setState({ ...state, codeImageModal: !state.codeImageModal });

  const _showCode = (data: any) => {
    if (showCode && data.code !== "") {
      setState({
        ...state,
        urlImageCode: data.url_image,
        codeImageModal: true,
        qrCode: data.code,
      });
    } else {
      setState({
        ...state,
        urlImageCode: "",
        codeImageModal: false,
        qrCode: "",
      });
    }
  };

  const Actions = (props: any) => {
    const { payload } = props;
    const _speaker: any = _actionMenuSpeaker(payload, handleSelectChange);

    return (
      <div className="row me-3">
        <div className="col-12">
          {session.profile?.roles?.find(
            (item: any) => item.name === "super-admin"
          ) &&
            payload.state !== "cancelado" &&
            payload.is_fail && (
              <>
                <Whisper
                  trigger="hover"
                  placement="auto"
                  controlId={`control-id-auto`}
                  speaker={<Tooltip>Favor revisar logs</Tooltip>}
                >
                  <span style={whisperStyle}>
                    <IoWarningOutline
                      aria-describedby="control-id-auto"
                      style={{ color: "red" }}
                    />
                  </span>
                </Whisper>

                <Divider vertical />
              </>
            )}
          <button
            className="btn px-0"
            onClick={() => handleSelectChange({ option: 2, id: payload.id })}
          >
            <ParagraphIcon />
          </button>
          <Divider vertical />
          <span style={{ cursor: "pointer" }}>
            <Whisper
              controlId={payload.id}
              placement="auto"
              trigger="click"
              speaker={(whisper_payload: any, ref: any) =>
                MoreMenu(whisper_payload, ref, _speaker)
              }
            >
              <MoreIcon />
            </Whisper>
          </span>
        </div>
      </div>
    );
  };

  const QrCode = (props: any) => {
    return (
      <div className="d-flex flex-column align-items-center">
        <img src={props.url} alt="code" />
        <div className="text-center bold-300">{state.qrCode}</div>
      </div>
    );
  };
  return (
    <Fragment>
      <ModalComponent
        open={state.viewModalOrder}
        size="sm"
        title="Detalle Orden"
        handleClose={_toggleViewOrderModal}
      >
        <FormOrder
          type="view"
          data={state.selectedData}
          reservation_types={reservation_types}
        />
      </ModalComponent>

      <ModalComponent
        open={state.viewHistoryModal}
        size="sm"
        title="Historico Orden"
        handleClose={_toggleViewHistoryModal}
      >
        <HistoryOrder
          data={state.selectedData}
          reservation_types={reservation_types}
        />
      </ModalComponent>

      <ModalComponent
        open={state.codeImageModal}
        title="QRCODE"
        handleClose={_toggleCodeImageModal}
        size="xs"
      >
        <QrCode url={state.urlImageCode} />
      </ModalComponent>

      <Table
        data={data.data}
        rowClassName="striped"
        autoHeight
        rowHeight={80}
        locale={{ emptyMessage: "No encontramos ningún dato." }}
      >
        <Table.Column align="center" verticalAlign="middle">
          <Table.HeaderCell
            verticalAlign="middle"
            align="center"
            style={{ padding: 0 }}
          >
            <Checkbox
              onChange={handleCheckAll}
              indeterminate={indeterminate}
              checked={checked}
            />
          </Table.HeaderCell>
          <Table.Cell>
            {(rowData) => {
              return (
                <Checkbox
                  onChange={handleCheck}
                  value={rowData.id}
                  checked={checkedKeys.some((item: any) => item === rowData.id)}
                />
              );
            }}
          </Table.Cell>
        </Table.Column>

        {tableHeader &&
          tableHeader.map((column: any, index: any) => (
            <Table.Column
              align={column.align}
              flexGrow={column.flexGrow}
              key={`table-column-${index}`}
            >
              <Table.HeaderCell>
                <span className="bold" style={{ textTransform: "capitalize" }}>
                  {column.label}
                </span>
              </Table.HeaderCell>

              <Table.Cell
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: column.alignFlex,
                }}
              >
                {(rowData) => {
                  switch (column.key) {
                    case "code":
                      return (
                        <button
                          className="btn p-0 m-0"
                          onClick={() => _showCode(rowData)}
                        >
                          <WhisperTooltip
                            className="size-08"
                            style={whisperStyle}
                            data={showCode ? rowData[column.key] : "******"}
                          />
                        </button>
                      );
                    case "delivery_user_id":
                      return (
                        <WhisperTooltip
                          className="size-08"
                          style={whisperStyle}
                          data={rowData.delivery_user?.name}
                        />
                      );
                    case "delivery_user_address":
                      return (
                        <WhisperTooltip
                          className="size-08"
                          style={whisperStyle}
                          data={rowData.delivery_user?.address}
                        />
                      );
                    case "delivery_user_number":
                      return (
                        <WhisperTooltip
                          className="size-08"
                          style={whisperStyle}
                          data={rowData.delivery_user?.apartment}
                        />
                      );
                    case "terminal":
                      return (
                        <WhisperTooltip
                          className="size-08"
                          style={whisperStyle}
                          data={rowData.terminal?.username}
                        />
                      );
                    case "company":
                      return (
                        <WhisperTooltip
                          className="size-08"
                          style={whisperStyle}
                          data={rowData.company?.name}
                        />
                      );
                    case "withdrawn_at":
                    case "delivered_at":
                      return (
                        <WhisperTooltip
                          style={whisperStyle}
                          className="size-08"
                          data={
                            rowData[column.key]
                              ? moment(rowData[column.key]).format(
                                  "DD/MM/YYYY H:mm:ss"
                                )
                              : ""
                          }
                        />
                      );
                    default:
                      return (
                        <WhisperTooltip
                          className="size-08 capitalize"
                          style={whisperStyle}
                          data={rowData[column.key]}
                        />
                      );
                  }
                }}
              </Table.Cell>
            </Table.Column>
          ))}

        <Table.Column align="right" flexGrow={1} verticalAlign="middle">
          <Table.HeaderCell>{""}</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => {
              return <Actions payload={rowData} />;
            }}
          </Table.Cell>
        </Table.Column>
      </Table>
    </Fragment>
  );
};
