import { ImSearch } from "react-icons/im";
import { TiPlus, TiThSmall } from "react-icons/ti";
import { Whisper } from "rsuite";
import ButtonIcon from "../../../components/ButtonIcon";
import { MoreMenu } from "../../../components/MoreMenu";
import { _actionMenuSpeakerAll } from "../speaker";
const Header = (props: any) => {
  const { handleToggleModal, handleSearchModal } = props;

  const Actions = ({ props }: any) => {
    const { handleSelectChange } = props;
    const _speaker: any = _actionMenuSpeakerAll(handleSelectChange);

    return (
      <Whisper
        placement="auto"
        trigger="click"
        speaker={(whisper_payload: any, ref: any) =>
          MoreMenu(whisper_payload, ref, _speaker)
        }
      >
        <button className="btn">
          <TiThSmall
            style={{ marginTop: "5px", width: "25px", height: "25px" }}
          />
        </button>
      </Whisper>
    );
  };

  return (
    <>
      <div>Empresas</div>
      <div className="d-flex">
        <ButtonIcon
          Icon={ImSearch}
          label=""
          width="20px"
          height="20px"
          style={{ marginTop: "5px" }}
          onClick={handleSearchModal}
        />

        <ButtonIcon
          Icon={TiPlus}
          label=""
          width="25px"
          height="25px"
          style={{ marginTop: "5px" }}
          onClick={handleToggleModal}
        />

        <Actions props={props} />
      </div>
    </>
  );
};

export default Header;
