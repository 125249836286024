import { Input } from "rsuite";
import ErrorValidation from "../../components/ErrorValidation";

const PasswordConnection = (props: any) => {
  const { state, _handleOnChange, type } = props;
  return (
    <>
      <div className="col-12 mb-2">
        <label className="size-08 bold-300 ms-1">Header (JSON)</label>
        {type && type === "view" ? (
          <div className="size-08 ms-2">{state.header}</div>
        ) : (
          <textarea
            className="w-100 border rounded"
            value={state.header}
            onChange={(event: any) =>
              _handleOnChange({
                target: { name: "header", value: event.target.value },
              })
            }
          />
        )}
        <ErrorValidation
          className="text-end size-08"
          name="header"
          validations={state.validations}
        />
      </div>

      <div className="col-12 mb-2">
        <label className="size-08 bold-300 ms-1">Usuario y contraseña (JSON)</label>
        {type && type === "view" ? (
          <div className="size-08 ms-2">{state.fields}</div>
        ) : (
          <textarea
            className="w-100 border rounded"
            value={state.fields}
            onChange={(event: any) =>
              _handleOnChange({
                target: { name: "fields", value: event.target.value },
              })
            }
          />
        )}
        <ErrorValidation
          className="text-end size-08"
          name="fields"
          validations={state.validations}
        />
      </div>

      <div className="col-12 mb-2">
        <label className="size-08 bold-300 ms-1">Url para generar token de acceso</label>
        {type && type === "view" ? (
          <div className="size-08 ms-2">{state.provider_url}</div>
        ) : (
          <Input
            placeholder="Url para generar token"
            value={state.create_token_url}
            onChange={(value: any) =>
              _handleOnChange({
                target: { name: "create_token_url", value },
              })
            }
          />
        )}
        <ErrorValidation
          className="text-end size-08"
          name="create_token_url"
          validations={state.validations}
        />
      </div>

      <div className="col-12 mb-2">
        <label className="size-08 bold-300 ms-1">Camino del token en la request</label>
        {type && type === "view" ? (
          <div className="size-08 ms-2">{state.token_path}</div>
        ) : (
          <Input
            placeholder="ej: profile.token"
            value={state.token_path}
            onChange={(value: any) =>
              _handleOnChange({
                target: { name: "token_path", value },
              })
            }
          />
        )}
        <ErrorValidation
          className="text-end size-08"
          name="token_path"
          validations={state.validations}
        />
      </div>

      <div className="col-12 mb-2">
        <label className="size-08 bold-300 ms-1">Url para envío</label>
        {type && type === "view" ? (
          <div className="size-08 ms-2">{state.provider_url}</div>
        ) : (
          <Input
            placeholder="Url para envío"
            value={state.provider_url}
            onChange={(value: any) =>
              _handleOnChange({
                target: { name: "provider_url", value },
              })
            }
          />
        )}
        <ErrorValidation
          className="text-end size-08"
          name="provider_url"
          validations={state.validations}
        />
      </div>
    </>
  )
}

export default PasswordConnection