import { useState } from "react";
import { Uploader } from "rsuite";
import { toBase64 } from "../../helpers/images";
import { AiOutlineFileAdd } from "react-icons/ai";

const UploadFile = (props: any) => {
  const { onError, onChange, accept, text } = props;
  const [state, setState] = useState<any>({ FileList: [] });

  const _handleOnChange = async (e: any) => {
    try {
      let imageData: any = null;
      if (e.length > 0) {
        imageData = await toBase64(e[e.length - 1].blobFile);
        setState({
          ...state,
          image: imageData,
          fileList: [e[e.length - 1]],
        });
      } else {
        setState({ ...state, image: null, fileList: [] });
      }
      onChange({ base64: imageData, file: e[e.length - 1] });
    } catch (e: any) {
      console.log(e);
      onError(e);
    }
  };
  return (
    <Uploader
      listType="text"
      onChange={_handleOnChange}
      fileList={state.fileList}
      onError={(e: any) => console.log("ERROR LOG", e.message)}
      autoUpload={false}
      action=""
      accept={accept || ""}
    >
      <button type="button" style={{ border: "2px solid #eee", padding: '5px 12px' }}>
        <AiOutlineFileAdd
          className="me-3"
          style={{ width: "20px", height: "20px" }}
        />
        {text || "Agregar archivo csv"}
      </button>
    </Uploader>
  );
};

export default UploadFile;
