import api from "./api";
import store from "../redux/store";

class OrderService {
  constructor() {
    const { session } = store.getState();
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${session.security_token}`;
  }

  list = async (search: any = null) => {
    try {
      let query: any = "";
      if (search) {
        query =
          "?" +
          Object.keys(search)
            .map((key: any) => `${key}=${search[key]}`)
            .join("&");
      }
      const response = await api.get(`/orders${query}`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  filterCsvData = async (search: any = null) => {
    try {
      let query: any = "";
      if (search) {
        query =
          "?" +
          Object.keys(search)
            .map((key: any) => `${key}=${search[key]}`)
            .join("&");
      }
      const response = await api.get(`/orders/get/filters/list/csv${query}`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  filter = async (search: any = null) => {
    try {
      let query: any = "";
      if (search) {
        query =
          "?" +
          Object.keys(search)
            .map((key: any) => `${key}=${search[key]}`)
            .join("&");
      }
      const response = await api.get(`/orders/get/filters/list${query}`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  selectList = async () => {
    try {
      const response = await api.get(`/orders/list/select-list`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  create = async (payload: any) => {
    try {
      const response = await api.post("/orders", payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  createPickupOrder = async (payload: any) => {
    try {
      const response = await api.post("/orders/pickup", payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  createPickupBoxOrder = async (payload: any) => {
    try {
      const response = await api.post("/orders/pickup-box", payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  createEcommerceOrder = async (payload: any) => {
    try {
      const response = await api.post("/orders/ecommerce", payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  createSamedayOrder = async (payload: any) => {
    try {
      const response = await api.post("/orders/sameday", payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  createMassivePickupBoxOrder = async (payload: any) => {
    try {
      const response = await api.post("/orders/massive/pickup-box", payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  update = async (payload: any) => {
    try {
      const response = await api.put(`/orders/update/${payload.id}`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  delete = async (id: any) => {
    try {
      const response = await api.delete(`/orders/${id}`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  deleteList = async (list: any) => {
    try {
      const response: any = await api.post(`/orders/delete-list`, {
        list: list,
      });
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

export default OrderService;
