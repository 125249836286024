import * as yup from "yup";

export const TerminalSchema = yup.object().shape({
  username: yup.string().required("Este campo es requerido"),
  name: yup.string().required("Este campo es requerido"),
  address: yup.string().required("Este campo es requerido"),
  door_com_number: yup
    .string()
    .min(4, "Mínimo 4 caracteres")
    .required("Este campo es requerido"),
  password: yup
    .string()
    .min(6, "Mínimo 6 caracteres")
    .required("Este campo es requerido"),
  qrcode_com_number: yup
    .string()
    .min(4, "Mínimo 4 caracteres")
    .required("Este campo es requerido"),
  module: yup.string().nullable().required("Este campo es requerido"),
  company: yup.string().nullable().required("Este campo es requerido"),
});

export type TerminalType = yup.InferType<typeof TerminalSchema>;
