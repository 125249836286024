/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, DatePicker, SelectPicker } from "rsuite";
import ChartBar from "../../components/ChartBar";
import ErrorValidation from "../../components/ErrorValidation";
import { _pushToastMessage } from "../../helpers";
import { _handleError } from "../../helpers/errors";
import validate from "../../helpers/validate";
import { loading_off, loading_on } from "../../redux/actions/loader";
import CompanyService from "../../services/company.service";
import DashboardService from "../../services/dashboard.service";
import DefaultTemplate from "../../templates/DefaultTemplate";
import { DashboardSchema } from "../../validations/dashboard.validations";
import { _createChartData, _createReservationsChartData } from "./helpers";

const Home = () => {
  const Companies = new CompanyService();
  const Dashboard = new DashboardService();

  const [companies, setCompanies] = useState([]);
  const [inputs, setInputs] = useState<any>({});
  const [chartData, setChartData] = useState<any>([]);
  const [chartReservationsData, setChartReservationsData] = useState<any>([]);
  const [orderData, setOrderData] = useState<any>([]);
  const [validations, setValidations] = useState({});
  const dispatch: any = useDispatch();

  useEffect(() => {
    _getCompanies();
  }, []);

  const _getCompanies = async () => {
    try {
      dispatch(loading_on());
      const companiesResponse = await Companies.selectList();
      setCompanies(companiesResponse.data);
      dispatch(loading_off());
    } catch (e: any) {
      _handleError(e, "No fue posible cargar las empresas");
      dispatch(loading_off());
    }
  };

  const _getChartData = async () => {
    try {
      const payload: any = {
        ...inputs,
        start: moment(inputs.start).format("yyyy-MM-DD"),
        end: moment(inputs.end).add(1, "day").format("yyyy-MM-DD"),
      };

      let validations: any = await validate(DashboardSchema, inputs);

      const daysRange: any = moment(payload.end).diff(
        moment(payload.start),
        "days"
      );

      setValidations(validations || {});

      if (daysRange > 30) {
        _pushToastMessage({
          type: "error",
          header: "Error",
          text: "El número máximo de días entre el campo desde y el campo hasta debe ser de 30 días.",
        });

        setChartData([]);
        setChartReservationsData([]);

        return;
      }

      if (validations) return;
      dispatch(loading_on());

      const DashboardResponse: any = await Dashboard.list(payload);

      if (DashboardResponse.data?.length === 0) {
        _pushToastMessage({
          type: "warning",
          header: "Aviso",
          text: "No se encontraron resultados con los filtros informados.",
        });
      }
      const chartData = _createChartData(DashboardResponse.data);
      const chartDataReservations = _createReservationsChartData(
        DashboardResponse.data
      );
      setOrderData(DashboardResponse.data);
      setChartData(chartData);
      setChartReservationsData(chartDataReservations);
      dispatch(loading_off());
    } catch (e: any) {
      _handleError(e, "No fue posible cargar la data");
      console.log(e);
      dispatch(loading_off());
    }
  };

  return (
    <DefaultTemplate>
      <div className="row with-header px-5 pt-4 mx-0">
        <div className="default-template-box">
          <div className="row py-3">
            <div className="col-4 px-3">
              <span className="size-09">Empresa</span>
              <SelectPicker
                className="w-100"
                data={companies}
                placeholder="Selecciona la empresa"
                value={inputs.company_id}
                onChange={(data: any) =>
                  setInputs({ ...inputs, company_id: data })
                }
              />
              <ErrorValidation
                validations={validations}
                name="company_id"
                className="size-08 text-end bold-300"
              />
            </div>
            <div className="col-4 px-4">
              <span className="size-09">Desde</span>
              <DatePicker
                className="w-100"
                placeholder="Informa fecha inicio"
                format="dd/MM/yyyy"
                onChange={(data: any) => setInputs({ ...inputs, start: data })}
                value={inputs.start}
              />
              <ErrorValidation
                validations={validations}
                name="start"
                className="size-08 text-end bold-300"
              />
            </div>
            <div className="col-4 px-4">
              <span className="size-09">Hasta</span>
              <DatePicker
                className="w-100"
                placeholder="Informa fecha termino"
                format="dd/MM/yyyy"
                onChange={(data: any) => setInputs({ ...inputs, end: data })}
                value={inputs.end}
              />
              <ErrorValidation
                validations={validations}
                name="end"
                className="size-08 text-end bold-300"
              />
            </div>

            <div className="col-12 px-4 pt-5 text-end">
              <Button appearance="primary" onClick={_getChartData}>
                {" "}
                Cargar{" "}
              </Button>
            </div>
          </div>

          {chartReservationsData && chartReservationsData.length > 0 && (
            <div className="row mx-0 px-4 py-5 justify-content-around">
              <div
                className="default-template-box"
                style={{ height: "400px", width: "90%" }}
              >
                <ChartBar
                  title="Historial de ordenes"
                  data={chartReservationsData}
                  colors={["#61CDBB", "#F47560", "#333333"]}
                  keys={["Iniciada", "Entregado", "Retirado"]}
                  indexBy="dia"
                />
              </div>
            </div>
          )}

          {chartData && chartData.length > 0 && (
            <div className="row mx-0 px-4 py-5 justify-content-around">
              <div
                className="default-template-box"
                style={{ height: "400px", width: "90%" }}
              >
                <ChartBar
                  title={`Estado actual de las ordenes (${
                    orderData?.length || 0
                  } ordenes encontradas)`}
                  data={chartData}
                  colors={["#61CDBB", "#F47560", "#333333"]}
                  keys={["Iniciada", "Entregado", "Retirado"]}
                  indexBy="dia"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </DefaultTemplate>
  );
};

export default Home;
