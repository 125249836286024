import * as yup from "yup";

export const CompanySchema = yup.object().shape({
  name: yup.string().nullable().required("Este campo es requerido"),
  phone: yup.string().nullable().required("Este campo es requerido"),
  address: yup.string().nullable().required("Este campo es requerido"),
  email: yup.string().email("Formato de email invalido").nullable().required("Este campo es requerido"),
  type_id: yup.string().nullable().required("Este campo es requerido"),
});

export type CompanyType = yup.InferType<typeof CompanySchema>;
