import * as yup from "yup";

export const MailerSchema = yup.object().shape({
  company_id: yup.string().nullable().required("Este campo es requerido"),
  template_name: yup.string().nullable().when("is_provider", {
    is: false,
    then: yup.string().nullable().required("Este campo es requerido"),
  }),
  provider_url: yup.string().nullable().when("is_provider", {
    is: true,
    then: yup.string().nullable().required("Este campo es requerido"),
  }),
  token_path: yup.string().nullable().when("is_password_connection", {
    is: true,
    then: yup.string().nullable().required("Este campo es requerido"),
  }),
  create_token_url: yup.string().nullable().when("is_password_connection", {
    is: true,
    then: yup.string().nullable().required("Este campo es requerido"),
  }),

});

export type MailerType = yup.InferType<typeof MailerSchema>;
