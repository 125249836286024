export default interface IBox {
  name: string;
  width: string;
  height: string;
  depht: string;
}

export const attributes = {
  company_name: "Empresa",
  template_name: "Template",
  status: "Estado",
};
