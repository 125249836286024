export default interface IUser {
  name: string;
  phone: string;
  address: string;
  rut: string;
  email: string;
  role: string,
  created_at: any;
}

export const attributes = {
  name: "Nombre",
  phone: "Telefono",
  address: "Dirección",
  rut: "Rut",
  email: "Email",
  roles: "Role",
  created_at: "Fecha Creación",
};
