export const inputs: any = {
  email: "",
  name: "",
  rut: "",
  phone: "",
  package_name: "",
  external_reference: "",
  terminal_id: "",
  start: null,
  boxes: [],
};
