/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, Toggle } from "rsuite";
import { _pushToastMessage } from "../../helpers";
import { _handleError } from "../../helpers/errors";
import { set_loader } from "../../redux/actions/loader";
import UserService from "../../services/user.service";

const FormExternalKey = (props: any) => {
  const { handleClose, handleConfirm, data } = props;
  const [state, setState] = useState<any>({
    renew: true,
  });
  const User = new UserService();
  const dispatch: any = useDispatch();

  useEffect(() => {
    if (data?.external_keys) {
      setState({ ...state, renew: false });
    }
  }, []);

  const _handleOnChange = (payload: any) => {
    const { target } = payload;
    state[target.name] = target.value;

    if (target.name === "renew" && !data?.external_keys) {
      _pushToastMessage({
        text: "Al crear las primeras llaves de acceso para um usuario, este campo es obligatorio.",
        type: "warning",
        header: "Master key",
      });
      state[target.name] = true;
    }
    setState({ ...state });
  };

  const _handleOnSubmit = async (e: any) => {
    e.preventDefault();

    if (!state.renew) {
      handleConfirm();
      return;
    }
    try {
      dispatch(set_loader({ is_loading: true }));
      const payload: any = {
        id: data?.id,
        renew: state.renew,
      };
      await User.createOrUpdateExternalKey(payload);
      handleConfirm();
    } catch (e: any) {
      dispatch(set_loader({ is_loading: false }));
      _handleError(e, e.message);
    }

    console.log(state, data);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-6 mb-3">
          <div className="size-08">Nombre</div>
          <div className="size-09 ms-2">{data?.name}</div>
        </div>

        <div className="col-6">
          <div className="size-08">Crear o renovar codigo</div>
          <div>
            <Toggle
              checked={state.renew}
              onChange={(e: any) =>
                _handleOnChange({ target: { value: e, name: "renew" } })
              }
            />
          </div>
        </div>

        <div className="col-12 mb-3">
          <div className="size-08">Api Key</div>
          <div className="size-09 ms-2">{data?.external_keys?.api_key}</div>
        </div>

        <div className="col-12 mb-3">
          <div className="size-08">Secret Key</div>
          <div className="size-09 ms-2">{data?.external_keys?.secret_key}</div>
        </div>
      </div>
      <form onSubmit={_handleOnSubmit}>
        <div className="row mt-4">
          <Modal.Footer>
            <Button onClick={handleClose} appearance="subtle">
              Cerrar
            </Button>
            <Button type="submit" appearance="primary">
              Confirmar
            </Button>
          </Modal.Footer>
        </div>
      </form>
    </div>
  );
};

export default FormExternalKey;
