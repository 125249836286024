import api from "./api";
import store from "../redux/store";

class RoleService {
  constructor() {
    const { session } = store.getState();
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${session.security_token}`;
  }

  list = async () => {
    try {
      const response = await api.get("/roles");
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  userlist = async (role:any) => {
    try {
      const response = await api.get(`/roles/user-list-by-roles?name=${role}`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  create = async (payload: any) => {
    try {
      const response = await api.post("/roles", payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

export default RoleService;
